var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.busy)?_c('div',{staticClass:"me",class:_vm.showIntervalJump
      ? _vm.uiConfig == 'both'
        ? 'ilarge'
        : _vm.uiConfig == 'input'
        ? 'imedium'
        : _vm.uiConfig == 'button'
        ? 'ismall'
        : ''
      : ''},[(_vm.uiConfig == 'both')?[_c('div',{ref:"JQuerySlot",staticClass:"JQuerySlot",class:{'with-ranges': _vm.shortcuts, 'no-sidebar': !_vm.hasSidebar}}),_c('div',{staticClass:"input-group daterange-container"},[(_vm.showIntervalJump)?_c('div',{staticClass:"input-group-addon btn btn-sm",attrs:{"title":_vm.$t('to_backward'),"disabled":!_vm.canEdit || _vm.rebuilding},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.jump(-1)}}},[_c('i',{staticClass:"fa fa-angle-left"})]):_vm._e(),_c('input',{ref:"inp",staticClass:"form-control",style:({
          'font-size': _vm.fontSize ? _vm.fontSize : '10pt',
          'border-right': 0
        }),attrs:{"type":"text","name":"datetimes","title":_vm.timezoneError
            ? _vm.$t('different_system_timezone_detected')
            : _vm.$t('titles.insert_date_interval'),"disabled":!_vm.canEdit || _vm.rebuilding},on:{"click":function($event){$event.stopPropagation();}}}),(_vm.showRefreshIcon)?_c('ToolTip',{attrs:{"icon":"fa fa-refresh refresh","title":_vm.$t('hints.integrated_refresh')}}):_vm._e(),_c('div',{staticClass:"input-group-addon btn btn-sm",attrs:{"title":_vm.timezoneError
            ? _vm.$t('different_system_timezone_detected')
            : _vm.$t('titles.insert_date_interval'),"disabled":!_vm.canEdit || _vm.rebuilding},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggle.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-calendar",class:_vm.timezoneError ? 'text-danger' : ''})]),(_vm.showIntervalJump)?_c('div',{staticClass:"input-group-addon btn btn-sm",attrs:{"title":_vm.$t('to_backward'),"disabled":!_vm.canEdit || !_vm.forwardEnabled || _vm.rebuilding},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.jump(1)}}},[_c('i',{staticClass:"fa fa-angle-right"})]):_vm._e()],1)]:_vm._e(),(_vm.uiConfig == 'button')?[_c('div',{ref:"JQuerySlot",staticClass:"JQuerySlot"}),_c('div',{ref:"inp"}),(_vm.showIntervalJump)?_c('div',{staticClass:"btn btn-sm",attrs:{"title":_vm.$t('to_backward'),"disabled":!_vm.canEdit || _vm.rebuilding},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.jump(-1)}}},[_c('i',{staticClass:"fa fa-angle-left"})]):_vm._e(),_c('span',{staticClass:"btn btn-sm",on:{"click":_vm.toggle}},[_c('i',{staticClass:"fa fa-calendar"})]),(_vm.showIntervalJump)?_c('span',{staticClass:"btn btn-sm",attrs:{"title":_vm.$t('to_backward'),"disabled":!_vm.canEdit || !_vm.forwardEnabled || _vm.rebuilding},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.jump(1)}}},[_c('i',{staticClass:"fa fa-angle-right"})]):_vm._e()]:_vm._e(),(_vm.uiConfig == 'input')?[_c('div',{ref:"JQuerySlot",staticClass:"JQuerySlot"}),_c('div',{staticClass:"input-group daterange-container"},[(_vm.showIntervalJump)?_c('div',{staticClass:"input-group-addon btn btn-sm",attrs:{"title":_vm.$t('to_backward'),"disabled":!_vm.canEdit || _vm.rebuilding},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.jump(-1)}}},[_c('i',{staticClass:"fa fa-angle-left"})]):_vm._e(),_c('input',{ref:"inp",staticClass:"form-control",style:({
          'font-size': _vm.fontSize ? _vm.fontSize : '10pt',
          'max-width': '150px'
        }),attrs:{"type":"text","name":"datetimes","title":_vm.timezoneError
            ? _vm.$t('different_system_timezone_detected')
            : _vm.$t('titles.insert_date_interval'),"disabled":!_vm.canEdit || _vm.rebuilding}}),(_vm.showRefreshIcon)?_c('ToolTip',{attrs:{"icon":"fa fa-refresh refresh","title":_vm.$t('hints.integrated_refresh')}}):_vm._e(),(_vm.showIntervalJump)?_c('div',{staticClass:"input-group-addon btn btn-sm",attrs:{"title":_vm.$t('to_backward'),"disabled":!_vm.canEdit || !_vm.forwardEnabled || _vm.rebuilding},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.jump(1)}}},[_c('i',{staticClass:"fa fa-angle-right"})]):_vm._e()],1)]:_vm._e()],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }