<template>
  <section>
    <PanelContractBinder
      homePanelName="EquipmentSearchCard"
      v-if="this.screenPath"
    />
    <GenericCardForm v-model="card" />
  </section>
</template>

<script>
import GenericCardForm from "@/components/control-sidebar/property-editors/generic-card-form.vue";
import PanelContractBinder from "@/components/control-sidebar/property-editors/panel-contract-binder.vue";
export default {
  name: "DetailFormConnectorCards",
  components: {
    GenericCardForm,
    PanelContractBinder
  },
  data() {
    return {
      showIconLibrary: false
    };
  },
  computed: {
    card: {
      set(value) {
        if (this?.panel?.options) {
          this.$set(this.panel.options, "card", value);
        }
      },
      get() {
        return (this.panel?.options && this.panel?.options?.card) || null;
      }
    },
    contract() {
      return this.$store.getters["user/contract"] || {};
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || { screenId: "" })
        .screenId;
    },
    panelOptions() {
      return this?.panel?.options || null;
    },
    screenPath() {
      return (
        (this.$store.getters["dashboard/screen"](this.screenId) || {})?.path ||
        null
      );
    }
  },
  watch: {
    panelOptions: {
      handler() {
        this.$store.dispatch("dashboard/saveDraftPanel", {
          panel: this.panel,
          screenId: this.screenId
        });
      },
      deep: true
    }
  },
  methods: {
    onPanelEvent($event) {
      switch ($event.action) {
        case "content:change": {
          break;
        }
      }
    },
    setFocus(id) {
      this.$nextTick(() => {
        let ref = `inpTitle${id}`;
        if ((this?.$refs || {})[ref].length) {
          this?.$refs[ref][0].focus();
        }
      });
    },
    resetTitle(qid) {
      this.columns.find(({ id }) => id == qid).title = "";
      this.setFocus(qid);
    }
  },
  created() {
    this.$root.$on("connector_list_card_form:event", this.onPanelEvent);
  },
  beforeDestroy() {
    this.$root.$off("connector_list_card_form:event", this.onPanelEvent);
  }
};
</script>

<style scoped>
.content-panel {
  padding: 0 2px;
  background-color: whitesmoke;
}

.content-panel > .content-style {
  padding: 2px 0 5px 5px;
  margin-top: -14px;
  padding-left: 5px;
  background-color: white;
}

.skin-dark .content-panel > .content-style {
  background-color: var(--skin-dark-dark);
}

.content-panel > .form-group > label {
  margin-top: 5px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.legend-control {
  background-color: transparent;
  z-index: 0;
  padding: 0 3px;
}

.legend-control:focus {
  outline-width: 0;
  border-color: #d2d6de;
}

.legend-label {
  position: relative;
}

.legend-label > div {
  position: absolute;
  top: -8px;
  left: 10px;
  font-size: 10pt;
  background-color: white;
  z-index: 1;
}
</style>
