<template>
  <div class="form-group">
    <label for="" style="width:100%">
      {{ $t("current_screen") }}
      <ToolTip :title="$t('hints.current_screen')" />
      <div class="pull-right" v-if="dashboardLink">
        <router-link
          class="fa fa-dashboard btn btn-xs no-style"
          :to="dashboardLink"
          :title="$t('dashboard')"
        ></router-link>
      </div>
    </label>
    <div
      class="inline-field"
      v-if="connector"
      :class="{
        disabled: busy || !can,
        active: isDefaultScreen
      }"
    >
      <div>
        <span
          class="fa fa-desktop"
          :class="{
            disabled: busy
          }"
        >
        </span>
        <span
          class="screen-name"
          :class="{
            active: isDefaultScreen,
            'text-danger': !currentConnectorScreen
          }"
          :title="$t('current_screen')"
        >
          {{
            (currentConnectorScreen && currentConnectorScreen.name) ||
              $t("not_available")
          }}
        </span>
        <div class="pull-right" v-if="!isNew && !isDefaultScreen">
          <span
            class="btn btn-xs"
            @click.stop.prevent="onSetAsDefaultScreen"
            :title="
              $t(
                isDefaultScreen
                  ? 'current_screen'
                  : 'titles.set_screen_as_default'
              )
            "
          >
            <span
              :class="busy ? 'fa fa-refresh fa-spin' : 'fa fa-exchange'"
            ></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectorService from "@/services/connector.js";
import ToolTip from "@/components/tooltip";
export default {
  name: "DashboardSwitcher",
  components: {
    ToolTip
  },
  props: {
    connectorId: {
      type: Number,
      required: false,
      default: 0
    }
  },
  data() {
    return {busy: false};
  },
  computed: {
    can() {
      // return false;
      return this.$can("manage", "EquipamentoCadastro");
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    screenId() {
      return this?.draft?.screenId ? parseInt(this?.draft?.screenId) : "";
    },
    screen() {
      return this.screenId && this?.screens?.length
        ? this.screens.find(({id}) => id == this.screenId)
        : null;
    },
    isNew() {
      return parseInt(this.screenId) < 0;
    },
    connector() {
      return (
        (this.connectorId &&
          (this.$store.getters["dashboard/connectorList"] || []).find(
            ({id}) => id == this.connectorId
          )) ||
        null
      );
    },
    isDefaultScreen() {
      return this.connector && this.connector.screen_id == this.screenId;
    },
    currentConnectorScreen() {
      let screen =
        this?.connector?.screen_id &&
        (this.screens || []).find(({id}) => id == this.connector.screen_id);
      if ((screen && screen.deleted_at) || !screen) {
        screen = (this.screens || []).find((item) => item.default);
      }
      return screen;
    },
    dashboardLink() {
      if (!this.currentConnectorScreen) return null;
      if (this.currentConnectorScreen.default || this.isDefaultScreen) {
        return `/dashboard/equipment/${this.connectorId}`;
      } else {
        return `/dashboard/equipment/${this.connectorId}/${this.screenId}`;
      }
    }
  },
  methods: {
    onSetAsDefaultScreen() {
      if (this.isNew || this.isDefaultScreen || this.busy || !this.can) return;
      this.$utils.confirm(this, "titles.set_screen_as_default").then((resp) => {
        if (!resp) return;
        this.setAsDefaultScreen();
      });
    },
    setAsDefaultScreen() {
      const srv = new ConnectorService();
      // get an updated connector version (etag) since status might have changed
      this.busy = true;
      srv
        .get(this.connector.id)
        .then((connector) => {
          if (connector && connector.id && connector.etag) {
            var payload = {
              id: connector.id,
              etag: connector.etag,
              screen_id: this.screenId
            };
            srv
              .save(payload)
              .then((ret) => {
                this.busy = false;
                if (ret && ret.id && ret.etag) {
                  this.$store.dispatch("dashboard/setConnectorPropertyValue", {
                    id: ret.id,
                    properties: {
                      etag: ret.etag,
                      screen_id: ret.screen_id
                    }
                  });
                }
              })
              .catch(() => {
                this.busy = false;
              });
          }
        })
        .catch(() => {
          this.busy = false;
        });
    }
  }
};
</script>

<style scoped>
.inline-field {
  /* margin: -14px 0 20px 0; */
  max-width: 100%;
  font-size: 12pt;
  white-space: nowrap;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  color: #888;
  border-radius: 4px;
  border: 1px solid lightgray;
  padding: 2px 5px 0 5px;
}

.skin-dark .inline-field {
  color: #b8c7ce;
  border: 1px solid #b8c7ce;
}

.clicable:hover {
  background-color: transparent !important;
  cursor: pointer;
  /* opacity: 0.8; */
}

.btn-exchange {
  position: relative;
  z-index: 1;
}

.btn-exchange-status {
  position: absolute;
  top: -1px;
  left: 4px;
  font-size: 65%;
  font-weight: 600;
  z-index: 1;
}

.btn-exchange.clicable:hover,
.active {
  color: #5e82a2;
}

.current-screen {
  color: #429f43;
}

.screen-name {
  text-overflow: clip ellipsis;
  padding-left: 5px;
  text-transform: capitalize;
  font-size: 13pt;
}

.skin-dark .active {
  color: #b8c7ce;
}

.screen-name.active {
  font-weight: 600;
}

.disabled,
.disabled:hover {
  color: #999;
  cursor: not-allowed !important;
}
.disabled .clicable:hover {
  cursor: not-allowed !important;
}
.no-style {
  color: inherit;
}
</style>
