<template>
  <section v-if="panelOptions">
    <PanelContractBinder
      homePanelName="EquipmentSearchAlarmTable"
      v-if="this.screenPath"
    />
    <DataListForm
      v-model="panelDataIdList"
      :dataSelectionOnly="false"
      :labelItems="$tc('selected', 2, {gender: 'o'})"
      :labelSelection="$tc(allAlarms ? 'alarm_list' : 'data_list', 2)"
      :showAddNewDataButton="!allAlarms"
      :showAddAllDataButton="!allAlarms"
      :dataListParser="dataListParser"
      @all="allAlarms = true"
    >
      <template v-slot:info>
        <div class="info">
          <div
            class="clicable"
            v-if="allAlarms"
            @click.stop.prevent="allAlarms = false"
            :title="$t('reset')"
          >
            {{ $t("show_all") }}
            <i class="fa fa-close"></i>
          </div>
          <div v-else>
            {{ $t("connector_standard") }}
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div style="margin: 0 0 10px 0">
          <div class="form-group">
            <label for="">{{ $utils.proper($t("sort_by")) }}</label>
            <select class="form-control" v-model="defaultOrder">
              <option
                v-for="item in orderList"
                :value="item.name"
                :key="item.name"
              >
                {{ $t(item.title) }}
              </option>
            </select>
          </div>
        </div>
        <div style="margin: 0 0 10px 0">
          <div class="form-group">
            <div class="form-check no-select">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="pagination"
                />
                <span>{{ $tc("pagination", 1) }}</span>
              </label>
            </div>
          </div>
        </div>
        <div style="margin: 0 0 10px 0" v-if="!allAlarms">
          <div class="form-group">
            <div class="form-check no-select">
              <label class="form-check-label">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="historyPanel"
                />
                <span>{{ $tc("synoptic.panels.history_panel", 1) }}</span>
              </label>
            </div>
          </div>
        </div>
      </template>
    </DataListForm>
    <TogglePanel
      :title="$tc('filter', 2)"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
    >
      <div class="panel-content">
        <draggable class="list-group" v-model="filterItems">
          <div
            class="list-group-item item group-item"
            :class="{
              'text-primary': selectedFilterName == item.name
            }"
            v-for="(item, ix) in filterItems"
            v-bind:key="ix"
          >
            <div :class="{disabled: !item.visible}">
              {{ $tc(item.title, 1) }}
            </div>
            <div class="item-buttons">
              <span
                class="btn btn-xs"
                v-on:click.prevent.stop="
                  selectedFilterName = item.name;
                  item.visible = true;
                "
              >
                <i
                  class="fa fa-dot-circle-o"
                  v-if="selectedFilterName == item.name"
                ></i>
                <i class="fa fa-circle-o" v-else></i> </span
              ><span
                class="btn btn-xs"
                v-on:click.prevent.stop="toggleFilterItem(item)"
              >
                <i class="fa fa-eye" v-if="item.visible"></i>
                <i class="fa fa-eye-slash" v-else></i>
              </span>
            </div>
          </div>
        </draggable>
        <div class="form-check no-select">
          <label class="form-check-label">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="inputSearch"
            /><span> {{ $t("text_search") }}</span>
          </label>
        </div>
      </div>
    </TogglePanel>
    <TogglePanel
      :title="$tc('columns', 1)"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
    >
      <div class="panel-content">
        <FieldListForm v-model="fields" />
      </div>
    </TogglePanel>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import draggable from "vuedraggable";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import Panels from "@/assets/dashboard/panels.json";
import DataListForm from "@/components/control-sidebar/property-editors/data-list-form.vue";
import FieldListForm from "@/components/control-sidebar/property-editors/field-list-form.vue";
import PanelContractBinder from "@/components/control-sidebar/property-editors/panel-contract-binder.vue";
const defAlarmPanel = () => {
  return Panels.find(
    (panel) => panel.template.template == "EquipmentAlarmPanel"
  );
};
const defAlarmFields = () => {
  return JSON.parse(JSON.stringify(defAlarmPanel().template.options.fields));
};
//priority | data_list | data_name | alarm_name | activation_datetime;
const defOrderList = () => [
  {name: "priority", title: "priority"},
  {name: "data_list", title: "data_list"},
  {name: "data_name", title: "data_name"},
  {name: "alarm_name", title: "alarm_name"},
  {name: "activation_datetime", title: "last_event"}
];
export {defAlarmPanel, defAlarmFields, defOrderList};

export default {
  name: "AlarmForm",
  components: {
    draggable,
    TogglePanel,
    DataListForm,
    FieldListForm,
    PanelContractBinder
  },
  data() {
    return {
      panelOptions: null
    };
  },
  computed: {
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    inputSearch: {
      set(value) {
        this.$set(this.panelOptions.filter, "inputSearch", value);
      },
      get() {
        if (this?.panelOptions?.filter) {
          return "inputSearch" in this.panelOptions.filter
            ? this.panelOptions.filter.inputSearch
            : true;
        } else {
          return (
            Panels.find(
              (panel) => panel.template.template == "EquipmentAlarmPanel"
            )?.template?.options?.filter?.inputSearch || true
          );
        }
      }
    },
    selectedFilterName: {
      set(name) {
        this.$set(this.panelOptions.filter, "selected", name);
      },
      get() {
        if (this?.panelOptions?.filter?.selected) {
          return this?.panelOptions?.filter?.selected;
        } else {
          return (
            this.$root.config?.alarm?.filter?.default ||
            Panels.find(
              (panel) => panel.template.template == "EquipmentAlarmPanel"
            )?.template?.options?.filter?.selected ||
            "attention"
          );
        }
      }
    },
    filterItems: {
      set(items) {
        this.$set(this.panelOptions.filter, "items", items);
      },
      get() {
        if (this?.panelOptions?.filter?.items) {
          return this?.panelOptions?.filter?.items;
        } else {
          return (
            Panels.find(
              (panel) => panel.template.template == "EquipmentAlarmPanel"
            )?.template?.options?.filter?.items || []
          );
        }
      }
    },
    panelDataIdList: {
      set(values) {
        this.$set(this.panelOptions, "dataList", values || []);
        if ((values || []).length) {
          this.allAlarms = false;
        }
      },
      get() {
        return this?.panelOptions?.dataList || [];
      }
    },
    fields: {
      set(value) {
        this.$set(
          this.panelOptions,
          "fields",
          JSON.parse(JSON.stringify(value))
        );
      },
      get() {
        return this?.panelOptions?.fields || defAlarmFields();
      }
    },
    orderList() {
      let lst = defOrderList();
      let dataList = this.panelDataIdList.filter(
        ({data_id}) => !/^connector.*/.test(data_id)
      );
      return dataList.length
        ? lst
        : lst.filter(({name}) => name != "data_list");
    },
    defaultOrder: {
      set(value) {
        this.$set(this.panelOptions, "defaultOrder", value);
      },
      get() {
        return this?.panelOptions?.defaultOrder || "priority";
      }
    },
    allAlarms: {
      set(value) {
        this.$set(this.panelOptions, "allAlarms", value);
        if (value) {
          this.panelDataIdList = [];
          this.historyPanel = false;
          this.pagination = true;
        }
      },
      get() {
        return this?.panelOptions?.allAlarms || false;
      }
    },
    historyPanel: {
      set(value) {
        this.$set(this.panelOptions, "historyPanel", value);
        if (value) {
          this.allAlarms = false;
        }
      },
      get() {
        return this?.panelOptions?.historyPanel || false;
      }
    },
    pagination: {
      set(value) {
        this.$set(this.panelOptions, "pagination", value);
      },
      get() {
        return this?.panelOptions?.pagination || false;
      }
    },
    screenPath() {
      return (
        (this.$store.getters["dashboard/screen"](this.screenId) || {})?.path ||
        null
      );
    }
  },
  watch: {
    panelOptions: {
      handler(n, o) {
        if (n) {
          this.$store.dispatch("dashboard/saveDraftPanel", {
            panel: {
              ...this.panel,
              options: {
                ...this.panel.options,
                ...this.panelOptions
              }
            },
            screenId: this.screenId
          });
        }
      },
      deep: true
    }
  },
  methods: {
    toggleFilterItem(item) {
      if (item.visible && this.selectedFilterName == item.name) return;
      item.visible = !item.visible;
    },
    dataListParser(lst) {
      return (lst || []).filter(
        ({id, is_local, memory_size}) =>
          (`${id}`.includes("data_group") || /^\d+$/g.test(id)) &&
          !is_local &&
          !((memory_size || 0) > 1)
      );
    }
  },
  created() {
    this.panelOptions = this?.panel?.options || null;
  }
};
</script>

<style scoped>
.list-group-item {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  cursor: move;
}

.list-group-item:hover {
  opacity: 0.8;
}

.list-group-item > .disabled {
  color: #999;
}

.form-check-label:hover {
  cursor: pointer;
  opacity: 0.8;
}

.form-check-label > input {
  vertical-align: middle;
  margin: 5px;
}

span.disabled {
  color: #999;
}

.panel-content {
  margin: 0 10px;
}

.info {
  margin: 0 12px 0 12px;
  padding: 5px;
  height: auto;
  color: #888;
}

.info > div {
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 14pt;
  text-align: center;
}

.info > div.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #337ab7;
}

.info > div > i {
  font-size: 80%;
  margin-left: 5px;
}
</style>
