<template>
  <div class="dimension-details" v-if="size">
    <span class="side"><i class="fa fa-arrows-v"></i></span>
    <span class="value">{{ info.h.toFixed(4) }}</span>
    <span class="unit">px</span> <span class="operation">/</span>
    <span class="side"><i class="fa fa-arrows-h"></i></span>
    <span class="value">{{ info.w.toFixed(4) }}</span>
    <span class="unit">px</span> =
    <span class="result">{{ info.a.toFixed(4) }}</span>
  </div>
</template>

<script>
export default {
  name: "SizeDisplay",
  props: {
    size: {
      type: Object,
      required: false,
      default: () => ({ w: 0, h: 0 })
    }
  },
  computed: {
    info() {
      let s = { w: parseFloat(this.size.w), h: parseFloat(this.size.h) };
      s.a = s.w && s.h ? s.h / s.w : 1;
      return s;
    }
  }
};
</script>

<style scoped>
.dimension-details {
  overflow: hidden;
  width: 100%;
  text-align: center;
  font-size: 80%;
  background: whitesmoke;
  padding: 2px;
}
.skin-dark .dimension-details {
  background: #222d32;
  color: #b8c7ce;
}
.side {
  color: rgb(153, 108, 26);
}
.skin-dark .side {
  color: #b8c7ce;
}
.side > i {
  width: 16px;
}
.value {
  color: #666;
}
.skin-dark .value {
  color: #728188;
}
.result {
  color: rgb(65, 65, 65);
}
.skin-dark .result {
  color: #728188;
}
.unit {
  color: rgb(80, 133, 89);
  font-style: italic;
  padding-left: 1px;
}
.skin-dark .unit {
  color: #b8c7ce;
}
.operation {
  color: #333;
  font-size: 10pt;
  font-weight: 600;
  padding: 0 2px;
}
</style>
