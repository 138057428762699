<template>
  <div class="modal" ref="publishForm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            v-on:click.stop="onCancel"
          >
            <span aria-hidden="true">×</span>
          </button>
          <h4 class="modal-title">
            <span class="screen-name">
              <i class="fa fa-globe" v-if="screen.public"></i>
              <i class="fa fa-briefcase" v-else></i>
              <span>
                {{ $t("remove") }} {{ $tc("screen") }} [#{{ screen.id
                }}<span v-if="panelName" data-testid="panel"
                  >/{{ panelName }}</span
                >]
              </span>
            </span>
          </h4>
        </div>
        <div class="modal-body">
          <div class="alert alert-error" role="alert" data-testid="alert">
            {{ $t("attention") }}! {{ $t("you_wont_be_able_to_revert_this")
            }}<br />
            {{ $t("are_you_sure") }}
            <button
              type="button"
              class="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <form action="" role="form">
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="">{{ $t("name") }}</label>
                <input
                  type="text"
                  class="form-control"
                  data-testid="name"
                  v-model="screen.name"
                  :placeholder="$t('name')"
                  disabled
                />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-12">
                <label for="">{{ $t("description") }}</label>
                <textarea
                  rows="2"
                  cols="80"
                  type="text"
                  data-testid="description"
                  class="form-control"
                  :placeholder="$t('description')"
                  v-model="screen.description"
                  disabled
                ></textarea>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-sm-3">
                <label for="">{{ $t("version") }}</label>
                <input
                  type="text"
                  class="form-control"
                  data-testid="revision-code"
                  placeholder="v1.00"
                  ref="version"
                  v-model="screen.revision_code"
                  disabled
                />
              </div>
              <div class="form-group col-sm-9">
                <label for="">{{ $t("release_notes") }}</label>
                <textarea
                  rows="3"
                  cols="80"
                  type="text"
                  class="form-control"
                  data-testid="revision-comment"
                  :placeholder="$t('release_notes')"
                  v-model="screen.revision_comment"
                  disabled
                ></textarea>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer text-right">
          <button
            class="btn btn-default"
            data-testid="close"
            v-on:click.stop="onCancel"
          >
            {{ $t("cancel") }}
          </button>
          <button
            class="btn btn-primary"
            data-testid="remove"
            v-on:click.stop="onConfirm"
            v-bind:disabled="!isValid || busy"
          >
            {{ $t("confirm") }}
          </button>
        </div>
        <div class="overlay overlay-local" v-if="busy">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContractService from "@/services/contract.js";
import ScreenService from "@/services/screen";
import MixinAlert from "@/project/mixin-alert.js";
export default {
  name: "UnpublishForm",
  mixins: [MixinAlert],
  props: {
    panelName: {
      type: String,
      required: false,
      default: () => ""
    },
    screenId: {
      type: [String, Number],
      required: false,
      default: () => 0
    }
  },
  data() {
    return {
      busy: false
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isValid() {
      return this.screen ? true : false;
    },
    screen() {
      return this.$store.getters["dashboard/screen"](this.screenId);
    }
  },
  methods: {
    onCancel() {
      this.close();
    },
    onConfirm() {
      let self = this;
      let screens = this.$store.getters["dashboard/screens"] || [];
      let ix = screens.findIndex((i) => i.id == this.screen.id);
      let contract_portal_data_payload = null;
      if (this?.contract?.portal_data?.search_page) {
        let search_page = {};
        let check = [0, 0];
        for (var p in this.contract.portal_data.search_page) {
          check[0] += 1;
          if (
            this.contract.portal_data.search_page[p].screen_id != this.screen.id
          ) {
            check[1] += 1;
            search_page[p] = JSON.parse(
              JSON.stringify(this.contract.portal_data.search_page[p])
            );
          }
        }
        if (check[0] != check[1]) {
          contract_portal_data_payload = this.contract.portal_data || {};
          contract_portal_data_payload.search_page = search_page;
        }
      }
      let srv = new ScreenService();
      self.busy = true;
      srv.remove(this.screen).then((ret) => {
        self.busy = false;
        if (self.validateDeleteResponse(ret)) {
          if (contract_portal_data_payload) {
            this.updateContractPortalData(contract_portal_data_payload);
          }
          self.$store.dispatch("dashboard/remove", self.screen.id);
          self.$store.commit("dashboard/SET_EDITOR_SCREEN_ID", null);
          self.showAlert(() => {
            self.close();
            self.$emit("removed", ix);
          });
        } else {
          self.showAlert();
        }
      });
    },
    close() {
      this.$emit("close");
      $(this.$refs.publishForm).modal("hide");
    },
    open() {
      $(this.$refs.publishForm).modal("show");
    },
    updateContractPortalData(portal_data) {
      let payload = {
        portal_data: JSON.parse(JSON.stringify(portal_data)),
        etag: this?.contract?.etag || "",
        id: this?.contract?.id || ""
      };
      if (!payload.id || !payload.etag) return null;
      if (!payload) return;
      this.savePortalData(payload);
    },
    savePortalData(payload) {
      const srv = new ContractService();
      srv.updateContract(payload).then((response) => {
        if (response && typeof response == "object" && response.id) {
          this.$store.dispatch("user/configureUserContract");
        } else {
          this.$swal({
            title: self.$t("item_could_not_be_saved"),
            text: self.$t("invalid_data"),
            icon: "error"
          });
        }
      });
    }
  },
  mounted() {
    let self = this;
    $(this.$refs.publishForm).on("shown.bs.modal", () => {
      self.$nextTick(() => {
        self.$refs.version.focus();
      });
      self.$emit("show");
    });
    self.open();
  }
};
</script>

<style scoped>
.overlay-local {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2em;
  text-align: center;
  color: #607d8b;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 1;
}

.skin-dark .overlay-local {
  color: #b8c7ce;
  background: #1e282c;
}

.overlay-local .fa-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
}

span.screen-name i {
  color: gray;
}
span.screen-name span {
  margin-left: 3px;
}
</style>
