var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{staticStyle:{"width":"100%"},attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("current_screen"))+" "),_c('ToolTip',{attrs:{"title":_vm.$t('hints.current_screen')}}),(_vm.dashboardLink)?_c('div',{staticClass:"pull-right"},[_c('router-link',{staticClass:"fa fa-dashboard btn btn-xs no-style",attrs:{"to":_vm.dashboardLink,"title":_vm.$t('dashboard')}})],1):_vm._e()],1),(_vm.connector)?_c('div',{staticClass:"inline-field",class:{
      disabled: _vm.busy || !_vm.can,
      active: _vm.isDefaultScreen
    }},[_c('div',[_c('span',{staticClass:"fa fa-desktop",class:{
          disabled: _vm.busy
        }}),_c('span',{staticClass:"screen-name",class:{
          active: _vm.isDefaultScreen,
          'text-danger': !_vm.currentConnectorScreen
        },attrs:{"title":_vm.$t('current_screen')}},[_vm._v(" "+_vm._s((_vm.currentConnectorScreen && _vm.currentConnectorScreen.name) || _vm.$t("not_available"))+" ")]),(!_vm.isNew && !_vm.isDefaultScreen)?_c('div',{staticClass:"pull-right"},[_c('span',{staticClass:"btn btn-xs",attrs:{"title":_vm.$t(
              _vm.isDefaultScreen
                ? 'current_screen'
                : 'titles.set_screen_as_default'
            )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSetAsDefaultScreen.apply(null, arguments)}}},[_c('span',{class:_vm.busy ? 'fa fa-refresh fa-spin' : 'fa fa-exchange'})])]):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }