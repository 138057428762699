<template>
  <section class="me">
    <div class="disabled-layer" v-if="$attrs.disabled"></div>
    <div
      class="form-group form-group-sm form-group-no-margin has-enphasis has-feedback"
    >
      <div class="input-group input-group-sm">
        <div
          v-if="dataValueIndex"
          class="input-group-addon btn"
          style="font-size: 80%; padding: 0 4px"
          @click.stop.prevent="onEditDataArray"
        >
          {{ $t("edit") }} [{{ memorySize }}] <i class="fa fa-pencil"></i>
        </div>
        <div
          v-else
          class="input-group-addon"
          style="font-size: 80%; padding: 0 4px"
        >
          {{ $t("test_value") }}
        </div>
        <input
          class="form-control text-center"
          id="testDataValue"
          ref="inp"
          v-model="value"
          @click.prevent.stop
          :placeholder="$t('not_persisted')"
          :type="inputType"
          data-testid="value"
        />
        <div
          class="input-group-addon btn"
          @click.prevent.stop="reset"
          data-testid="reset"
        >
          <i class="fa fa-close"></i>
        </div>
        <div
          class="input-group-addon btn"
          :class="isSimulating ? 'text-orange' : ''"
          v-on:click.stop.prevent="toggleSimulation()"
          :title="$t('simulation')"
        >
          <i class="fa fa-history"></i>
        </div>
      </div>
      <div
        class="slidecontainer"
        v-if="inputType != 'text' && dataType != 'bool'"
      >
        <input
          type="range"
          :min="slider.min"
          :max="slider.max"
          v-model="sliderValue"
          class="slider"
        />
      </div>
    </div>
    <portal to="modal" v-if="dataArray">
      <FormDataArray
        ref="formDataArray"
        :modal="true"
        :data="dataArray"
        @save="onSaveDataArray"
        @close="dataArray = null"
      />
    </portal>
  </section>
</template>

<script>
import {debounce} from "lodash";
import FormDataArray from "@/components/registration/form-data-array.vue";
export default {
  name: "TestDataValue",
  components: {
    FormDataArray
  },
  props: {
    dataId: {
      type: [Number, String],
      required: true,
      default: () => 0
    },
    historySimulation: {
      type: String,
      required: false,
      default: () => "auto"
    },
    dataValueIndex: {
      type: Object,
      required: false,
      default: null
    },
    arrayEditor: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      colapsed: true,
      lastValue: "",
      slider: {
        min: 0,
        max: 100,
        value: 50
      },
      dataArray: null
    };
  },
  computed: {
    isSimulating() {
      if (!this.equipmentData) return false;
      let entries = this.$store.getters["history/entries"] || {};
      return ((entries[this.equipmentData.id] || {})?.samples || []).length > 0;
    },
    showHistorySimulation() {
      if (this.historySimulation == "auto") {
        return this?.equipmentData?.history_enabled || false;
      }
      return this.historySimulation;
    },
    dataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    equipmentData() {
      return this.dataList.find((data) => data.id == this.dataId);
    },
    memorySize() {
      return this?.equipmentData?.memory_size ?? -1;
    },
    dataType() {
      return this?.equipmentData?.type || "";
    },
    inputType() {
      return isNaN(Number(this.dataId)) || this.dataType == "string"
        ? "text"
        : "number";
    },
    value: {
      set(value) {
        let id = this.dataId;
        let vlr = this.$root.$formatter.valueCast(this.dataType, value);
        this.lastValue = value;
        if (isNaN(Number(this.dataId))) {
          let cfg = {
            action: this.dataId.match(/connector/)
              ? "dashboard/setConnectorValue"
              : "dashboard/setDeviceValue",
            is_connected: this.dataId.match(/connector/)
              ? "connector_status_number"
              : "device_status_number",
            has_active_alarms: this.dataId.match(/connector/)
              ? "has_active_alarms"
              : "has_active_alarms"
          };
          //'connector_5066_is_connected'
          //'device_11418_is_connected'
          if (/(connector_|device_)\d+/.test(this.dataId)) {
            id = this.dataId.match(/\d+/)[0];
          } else {
            let connector =
              this.$store.getters["dashboard/dashboardEquipment"] || null;
            if (
              connector &&
              connector.base_model &&
              this.dataId.replace(/connector_/, "") != this.dataId
            ) {
              id = connector.id;
            }
          }
          if (id) {
            let propName = this.dataId.replace(
              /(connector|device)_[\d\_]*/,
              ""
            );
            if (/is_connected/.test(propName)) {
              let entry = {
                id: id
              };
              entry[cfg["is_connected"]] = parseInt(value) ? 1 : 2;
              this.$store.dispatch(cfg["action"], entry);
            } else if (/has_active_alarms/.test(propName)) {
              let entry = {
                id: id
              };
              entry[cfg["has_active_alarms"]] = parseInt(value) ? true : false;
              this.$store.dispatch(cfg["action"], entry);
            } else {
              let entry = {connector_id: id};
              entry[propName] = value;
              this.$store.dispatch("dashboard/setConnectorPropertyValue", {
                connector_id: id,
                properties: entry
              });
            }
          }
        } else {
          if (this.dataValueIndex) {
            let lst = [...new Array(this?.equipmentData?.memory_size || 1)].map(
              (i, ix) => this.$root.$formatter.rawValue(this?.equipmentData, ix)
            );
            if (this.dataValueIndex.type == "constant") {
              if (
                this.dataValueIndex.value >= 0 &&
                this.dataValueIndex.value < lst.length
              ) {
                lst[this.dataValueIndex.value] = vlr;
                vlr = lst.join(",");
              } else {
                return;
              }
            } else if (
              this.dataValueIndex.type == "data" &&
              this.dataValueIndex.data_id
            ) {
              id = this.dataValueIndex.data_id;
            } else {
              return;
            }
          }
          this.$store.dispatch("dashboard/setDataValue", {
            id: id,
            value: vlr
          });
          if (
            this.dataType != "string" &&
            this.dataType != "bool" &&
            this.lastValue !== "" &&
            this.slider.value != parseInt(this.lastValue)
          ) {
            this.sliderValue = parseInt(this.lastValue);
          }
          this.$root.$emit("simulation:update", this.dataId);
        }
      },
      get() {
        let value = this.lastValue;
        if (typeof value === "boolean") {
          value = value ? 1 : 0;
        }
        return value;
      }
    },
    sliderValue: {
      set(value) {
        if (isNaN(Number(value))) return;
        this.slider.value = parseInt(value);
        if (this.lastValue != this.slider.value) {
          this._updateFromSlider();
        }
      },
      get() {
        return this.slider.value ?? 50;
      }
    }
  },
  watch: {
    dataId: {
      handler(n, o) {
        if (n && o && n == o) return;
        if (this.equipmentData) {
          let value = (this?.equipmentData?.current_value || {}).value;
          if (this.dataValueIndex) {
            let entry = {
              ...this.equipmentData,
              portal_data: {data_value_index: this.dataValueIndex}
            };
            let ix = this.$root.$formatter.dataValueCurrentIndex(entry);
            if (ix >= 0 && ix < this.memorySize) {
              if (this.dataValueIndex.type == "constant") {
                value = this.$root.$formatter.rawValue(entry, ix);
              } else if (
                this.dataValueIndex.type == "data" &&
                this.dataValueIndex.data_id
              ) {
                value = ix;
              }
            }
          }
          if (this.dataType == "bool") {
            this.lastValue = value ? 1 : 0;
            this.slider.min = 0;
            this.slider.max = 1;
          } else {
            this.lastValue = this.$root.$formatter.valueCast(
              this.dataType,
              value
            );
            if (this.equipmentData.minimum_value !== "") {
              this.slider.min = parseInt(this.equipmentData.minimum_value);
            } else {
              this.slider.min = this.slider.value - (this.slider.value || 0);
            }
            if (this.equipmentData.maximum_value !== "") {
              this.slider.max = parseInt(this.equipmentData.maximum_value);
            } else {
              this.slider.max = this.slider.value + (this.slider.value || 100);
            }
          }
          this.slider.value = this.lastValue !== "" ? this.lastValue : 50;
        } else {
          this.lastValue = "";
        }
      },
      immediate: true
    }
  },
  methods: {
    toggleSimulation() {
      if (this?.equipmentData?.id) {
        this.value = parseInt(Math.random() * 10000) / 100;
        if (this.equipmentData.history_enabled) {
          this.$store.dispatch("history/simulate", this.equipmentData.id);
        }
      }
    },
    toggle() {
      this.colapsed = !this.colapsed;
      if (!this.colapsed) {
        this.$nextTick(() => {
          if (this.$refs.inp) {
            this.$refs.inp.focus();
          }
        });
      }
    },
    reset() {
      let value = "";
      this.lastValue = "";
      if (this.dataValueIndex) {
        if (this.dataValueIndex.type == "constant") {
          let entry = {
            ...this.equipmentData,
            current_value:
              this.equipmentData.restore ?? this.equipmentData.current_value,
            portal_data: {data_value_index: this.dataValueIndex}
          };
          let ix = this.$root.$formatter.dataValueCurrentIndex(entry);
          if (ix >= 0 && ix < this.memorySize)
            value = this.$root.$formatter.rawValue(entry, ix);
        } else if (
          this.dataValueIndex.type == "data" &&
          this.dataValueIndex.data_id
        ) {
          let data = this.dataList.find(
            ({id}) => parseInt(id) === parseInt(this.dataValueIndex.data_id)
          );
          if (data?.restore && data.restore.value !== null) {
            value = data.restore.value;
          }
        } else {
          return;
        }
      } else {
        if (
          this?.equipmentData?.restore &&
          this.equipmentData.restore.value !== null
        ) {
          value = this.equipmentData.restore.value;
        }
        if (this.equipmentData.history_enabled) {
          this.$store.dispatch("history/resetData", this.equipmentData.id);
        }
      }
      this.value = value;
      if (this.$refs.inp) {
        this.$refs.inp.focus();
      }
    },
    onEditDataArray() {
      if (this.arrayEditor) {
        this.dataArray = JSON.parse(JSON.stringify(this.equipmentData));
      } else {
        this.$emit("editArray", this.equipmentData);
      }
    },
    onSaveDataArray(fields) {
      let value = (fields || []).map(({value}) => value).join(",");
      this.$store.dispatch("dashboard/setDataValue", {
        id: this.equipmentData.id,
        value: value
      });
      this.$refs.formDataArray.close();
    }
  },
  created() {
    this._updateFromSlider = debounce(() => {
      if (isNaN(Number(this.slider.value))) return;
      if (parseInt(this.lastValue) != parseInt(this.slider.value)) {
        this.value = this.slider.value;
      }
    }, 300);
  }
};
</script>

<style scoped>
section.me {
  margin: 0;
  padding: 0;
  position: relative;
}

.disabled-layer {
  opacity: 0.2;
  background-color: #ddd;
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -15px;
  cursor: not-allowed;
  z-index: 9999;
  border-radius: 4px;
}

.colapsed {
  padding-bottom: 0px;
}

.clicable:hover {
  cursor: pointer;
}

.slidecontainer {
  width: 100%;
  z-index: 2;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 3px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  border-radius: 0 0 5px 5px;
}

.skin-dark .slider {
  background: #728188;
}

.slider:hover {
  opacity: 1;
  z-index: 3;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: 5px;
  appearance: none;
  width: 5px;
  height: 10px;
  background: #4e789b;
  border-radius: 0 0 3px 3px;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  margin-top: 5px;
  appearance: none;
  width: 5px;
  height: 10px;
  background: #737473;
  border-radius: 0 0 3px 3px;
  cursor: pointer;
}
</style>
