<template>
  <div :key="screenId">
    <ul class="nav nav-tabs">
      <li
        :class="tab == 'component' ? 'active' : ''"
        :title="$t('properties')"
        @click.stop.prevent="tab = 'component'"
      >
        <a href="#MainFormTab1" data-toggle="tab" aria-expanded="true">
          <template v-if="currentDraftPanel">
            <i class="fa fa-panel"></i>
            <span>{{ $tc("panel", 1) }}</span>
          </template>
          <template v-else>
            <i class="fa fa-desktop" style="padding-top: 2px"></i>
            <span>{{ $tc("screen", 1) }}</span>
          </template>
        </a>
      </li>
      <li
        :class="tab == 'components' ? 'active' : ''"
        :title="$t('controls')"
        @click.stop.prevent="tab = 'components'"
      >
        <a href="#MainFormTab2" data-toggle="tab" aria-expanded="true">
          <i class="fa fa-columns"></i>
          <span>{{ $tc("layout", 1) }}</span>
        </a>
      </li>
      <li
        :class="tab == 'events' ? 'active' : ''"
        :title="`${$tc('script', 2)}/${$t('events')}`"
        v-if="showEventsTab"
        @click.stop.prevent="tab = 'events'"
      >
        <a href="#MainFormTab3" data-toggle="tab" aria-expanded="true">
          <template v-if="currentDraftPanel">
            <i class="fa fa-flash"></i> <span>{{ $t("actions") }}</span>
          </template>
          <template v-else>
            <i class="fa fa-file-code-o"></i>
            <span>{{ $tc("script", 2) }}</span>
          </template>
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        class="tab-pane"
        :class="tab == 'component' ? 'active' : ''"
        id="MainFormTab1"
      >
        <PanelForm v-if="currentDraftPanel" />
        <DashboardForm v-else />
      </div>
      <div
        class="tab-pane"
        :class="tab == 'components' ? 'active' : ''"
        id="MainFormTab2"
      >
        <TogglePanel
          :title="$t('available_layouts')"
          :collapsed="togglePanels.layouts.collapsed"
          @changed="togglePanels.layouts.collapsed = $event"
          persistent="toggle_available_layouts"
        >
          <template v-slot:toolbar>
            <ListModePicker
              v-if="!togglePanels.layouts.collapsed"
              class="toogle-panel-custom-toolbar"
              v-model="togglePanels.layouts.viewMode"
              @newOrder="
                togglePanels.layouts.order = !togglePanels.layouts.order
              "
              :enabled="['list', 'inline']"
              settingsKey="layout_gallery"
            />
          </template>
          <LayoutGallery
            :view="togglePanels.layouts.viewMode"
            :order="togglePanels.layouts.order"
          />
        </TogglePanel>
        <TogglePanel
          :title="$t('available_panels')"
          :collapsed="togglePanels.panels.collapsed"
          @changed="togglePanels.panels.collapsed = $event"
          persistent="toggle_available_panels"
        >
          <template v-slot:toolbar>
            <ListModePicker
              v-if="!togglePanels.panels.collapsed"
              class="toogle-panel-custom-toolbar"
              v-model="togglePanels.panels.viewMode"
              @newOrder="togglePanels.panels.order = !togglePanels.panels.order"
              settingsKey="panel_gallery"
            />
          </template>
          <PanelGallery
            :view="togglePanels.panels.viewMode"
            :order="togglePanels.panels.order"
          />
        </TogglePanel>
        <TogglePanel
          :title="$t('dashboard_panels')"
          persistent="toggle_dashboard_panels"
        >
          <PanelList />
        </TogglePanel>
        <!-- IMPORTANT: This feature was disabled in the production environment on 2025-03-06 (maz) -->
        <TogglePanel :title="`${$tc('custom_page', 2)}`" v-if="false">
          <div class="text-center" style="padding:20px 0">
            <HTMLForm v-model="htmlForm" />
          </div>
        </TogglePanel>
      </div>
      <div
        class="tab-pane"
        :class="tab == 'events' ? 'active' : ''"
        id="MainFormTab3"
        v-if="showEventsTab"
      >
        <PanelEventsForm v-if="customizableToolbar" />
        <DashboardEventsForm v-else />
      </div>
    </div>
  </div>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import PanelForm from "@/components/control-sidebar/property-editors/panel-form.vue";
import PanelList from "@/components/control-sidebar/property-editors/panel-list.vue";
import LayoutGallery from "@/components/control-sidebar/property-editors/layout-gallery.vue";
import PanelGallery from "@/components/control-sidebar/property-editors/panel-gallery.vue";
import PanelEventsForm from "@/components/control-sidebar/property-editors/panel-events-form.vue";
import DashboardEventsForm from "@/components/control-sidebar/property-editors/dashboard-events-form.vue";
import DashboardForm from "@/components/control-sidebar/property-editors/dashboard-form.vue";
import ListModePicker from "@/components/editor/list-mode-picker.vue";
import HTMLForm from "@/components/control-sidebar/property-editors/html-form.vue";
import {getPanelTemplate} from "@/services/dashboard.js";
export default {
  name: "MainForm",
  components: {
    TogglePanel,
    PanelForm,
    DashboardForm,
    PanelList,
    LayoutGallery,
    PanelGallery,
    PanelEventsForm,
    DashboardEventsForm,
    ListModePicker,
    HTMLForm
  },
  data() {
    return {
      togglePanels: {
        layouts: {
          collapsed: true,
          viewMode: "inline",
          order: undefined
        },
        panels: {
          collapsed: true,
          viewMode: "tree",
          order: false
        }
      },
      tab: "components", // component | components | events,
      iHTMLForm: null
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    template() {
      return this?.draft?.template;
    },
    screenId() {
      return this.draft?.screenId || null;
    },
    showEventsTab() {
      if (!this.currentDraftPanel) return true;
      return this.customizableToolbar;
    },
    currentDraftPanel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    customizableToolbar() {
      if (!this?.currentDraftPanel?.template) return false;
      const panel = getPanelTemplate(this?.currentDraftPanel?.template);
      return (panel && panel.customizableToolbar) || false;
    },
    htmlForm: {
      set(value) {
        this.iHTMLForm = value;
        if (value) {
          this.template.wrapper = {
            html_body: value.html,
            style_sheet: value.style,
            javascript: value.javascript,
            content_css_vars: value.css_vars
          };
        } else {
          this.template.wrapper = null;
        }
        this.saveDraft(this.template);
      },
      get() {
        return this.iHTMLForm;
      }
    }
  },
  watch: {
    screenId: {
      handler(n, o) {
        if (n && n != o) {
          this.initHTMLForm();
        }
      },
      immediate: true
    }
  },
  methods: {
    onDrawerEvent($event) {
      if (!$event) return;
      switch ($event.action) {
        // app.__vue__.$root.$emit("drawer:event",{action:"tab:activate", details:"components"})
        case "tab:activate": {
          this.tab = $event.details; // component | components | events
        }
      }
    },
    saveDraft(template) {
      this.$store.dispatch("dashboard/saveDraft", {
        screenId: this.screenId,
        template: template
      });
    },
    initHTMLForm() {
      if (this?.template?.wrapper) {
        this.iHTMLForm = {
          html: this?.template?.wrapper?.html_body || "",
          style: this?.template?.wrapper?.style_sheet || "",
          javascript: this?.template?.wrapper?.javascript || "",
          css_vars: this?.template?.wrapper?.content_css_vars || ""
        };
      } else {
        this.iHTMLForm = null;
      }
    }
  },
  created() {
    this.$root.$on("drawer:event", this.onDrawerEvent);
  },
  beforeDestroy() {
    this.$root.$off("drawer:event", this.onDrawerEvent);
  }
};
</script>

<style scoped>
.nav {
  display: flex;
}

.nav-tabs {
  min-height: 40px;
}

.nav li {
  flex: 1;
}

.nav li a {
  display: flex;
  justify-content: center;
  width: 100%;
  opacity: 0.6;
}

.nav li a:hover {
  opacity: 1;
}

.nav li.active a {
  opacity: 1;
}

.nav > li > a {
  color: inherit;
}
.nav > li > a > span {
  margin: -2px 0 0 5px;
}

.toogle-panel-custom-toolbar {
  display: inline-block;
  margin-top: 6px;
  margin-right: 6px;
}
</style>
