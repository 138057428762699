<template>
  <DetailForm persistent="toggle_dashboard_launcher_panel">
    <DetailFormDashboardLauncherPanel />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormDashboardLauncherPanel from "@/components/control-sidebar/property-editors/detail-form-dashboard-launcher-panel.vue";
export default {
  name: "DashboardLauncherPanelForm",
  components: {
    DetailForm,
    DetailFormDashboardLauncherPanel
  }
};
</script>
