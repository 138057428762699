<template>
  <div class="form-group form-group-sm">
    <label :for="this.inpId" v-if="label">
      {{ $t(label) }}
      <ToolTip v-if="hint" :title="hint" />
    </label>
    <div class="input-group">
      <input
        :id="this.inpId"
        type="text"
        class="form-control"
        ref="inp"
        :placeholder="placeholder"
        v-model="iValue"
      />
      <div
        class="input-group-addon btn"
        :title="$t('reset')"
        @click.stop.prevent="reset"
      >
        <i class="fa fa-close"></i>
      </div>
    </div>
  </div>
</template>

<script>
import ToolTip from "@/components/tooltip.vue";
export default {
  name: "BootstrapFormInput",
  components: {
    ToolTip
  },
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: ""
    },
    hint: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      lValue: ""
    };
  },
  computed: {
    iValue: {
      set(value) {
        this.lValue = value;
        this.$emit("input", value);
      },
      get() {
        return this.lValue;
      }
    }
  },
  methods: {
    reset() {
      this.iValue = "";
      this.$refs.inp.focus();
    }
  },
  beforeCreate() {
    this.inpId = this.$utils.uuid();
  },
  created() {
    this.lValue = this.value;
  }
};
</script>

<style scoped></style>
