<template>
  <div class="user-messages"></div>
</template>

<script>
export default {
  name: "UserMessages",
  data() {
    return {
      messages_pending: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"]
    },
    userMessages() {
      return (this?.user?.user_profile?.portal_data?.messages || []);
    }
  },
  watch: {
    userMessages: {
      handler(n, o) {
        if (!o && n && n.length > 0 && !this.messages_pending) {
          this.messages_pending = true;
          setTimeout(() => this.showMessages(n.slice(0)), 1000);
        }
      },
      immediate: true
    }
  },
  methods: {
    showMessages(messages) {
      if (!messages || !messages.length) {
        this.clearMessages();
        return;
      }
      const message = messages[0];
      if (!message.title || !message.text) {
        return;
      }
      this.$swal({
        title: message.title,
        text: message.text,
        icon: message.icon ?? "info"
      }).then(() => this.showMessages(messages.slice(1)));
    },
    clearMessages() {
      this.messages_pending = false;
      let payload = {};
      payload.user_profile = structuredClone(this?.user?.user_profile || {});
      if (this?.user?.user_profile?.portal_data) {
        payload.user_profile.portal_data.messages = [];
      } else {
        payload.user_profile.portal_data = {'messages': []};
      }
      this.$nextTick(() => {
        this.$store.dispatch("user/updateProfile", payload);
      });
    }
  }
}
</script>