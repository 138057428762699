<template>
  <section>
    <TogglePanel
      :title="$tc('titles.selector', 2)"
      :layout="($attrs && $attrs.layout) || 'box'"
      style="z-index:100"
    >
      <label for="">
        {{ $tc("titles.option", 2) }}
        <ToolTip :title="$tc('hints.option', 1)" />
      </label>
      <TogglePanel
        v-for="selector in selectorList"
        :key="selector.name"
        :title="$tc(`titles.${selector.name}`, 1)"
        layout="compressed"
        accordion="dashboard_launcher_panel"
        style="z-index:100"
      >
        <SelectorForm
          :value="selector"
          @input="updateSelector($event)"
          :sample="getSample(selector)"
        >
        </SelectorForm>
      </TogglePanel>
    </TogglePanel>
    <TogglePanel
      :title="$tc('titles.screen', 1)"
      :layout="($attrs && $attrs.layout) || 'box'"
      style="z-index:100"
    >
      <div class="form-group form-group-sm">
        <label for="screenSource">
          {{ $t("destination") }}
          <ToolTip :title="$tc('hints.screen', 1)" />
        </label>
        <select name="" id="" class="form-control" v-model="screenSource">
          <option value="current">{{ $tc("current_screen", 0) }}</option>
          <option value="connector" v-if="isSelectorEnabled('connector')">{{
            $tc("connector_standard", 1)
          }}</option>
          <option value="device" v-if="isSelectorEnabled('device')">{{
            $tc("device_standard", 1)
          }}</option>
          <option value="custom">{{ $tc("user_defined", 1) }}</option>
        </select>
      </div>
      <ScreenSelector
        v-model="targetScreenId"
        v-if="screenSource == 'custom'"
      />
    </TogglePanel>
  </section>
</template>

<script>
import Panels from "@/assets/dashboard/panels.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import SelectorForm from "@/components/control-sidebar/property-editors/selector-form.vue";
import ScreenSelector from "@/components/editor/screen-selector.vue";
import ToolTip from "@/components/tooltip.vue";
import SelectableObjects from "@/assets/dashboard/selectable_objects.json";
import messages from "@/i18n/equipment-selectors.js";

const defPanel = () => {
  return Panels.find(
    (panel) => panel.template.template == "DashboardLauncherPanel"
  ).template;
};

export default {
  name: "DetailFormDashboardLauncherPanel",
  i18n: {messages},
  components: {
    TogglePanel,
    SelectorForm,
    ScreenSelector,
    ToolTip
  },
  data: () => {
    return {
      panelOptions: null
    };
  },
  computed: {
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    selectorList() {
      return this?.panelOptions?.selector_list || [];
    },
    targetScreenId: {
      set(value) {
        this.panelOptions.screen_id = value;
        this.saveDraft();
      },
      get() {
        return this.panelOptions.screen_id;
      }
    },
    screenSource: {
      set(value) {
        this.panelOptions.screen_source = value;
        if (!this.panelOptions.screen_source != "custom") {
          this.targetScreenId = "";
          return;
        }
        this.saveDraft();
      },
      get() {
        return this.panelOptions.screen_source;
      }
    }
  },
  methods: {
    updateSelector(value) {
      if (!value || !value.name) return;
      const ix = this.panelOptions.selector_list.findIndex(
        ({name}) => name == value.name
      );
      this.$set(this.panelOptions.selector_list, ix, {...value});
      this.saveDraft();
    },
    saveDraft() {
      this.$store.dispatch("dashboard/saveDraftPanel", {
        setAsCurrent: true,
        screenId: this.screenId,
        panel: {
          ...this.panel,
          options: {
            ...this.panel.options,
            ...{
              // important: pick the panel properties only, since it might have been coupled to another panel
              screen_source: this.panelOptions.screen_source,
              screen_id: this.panelOptions.screen_id,
              selector_list: this.panelOptions.selector_list
            }
          }
        }
      });
    },
    isSelectorEnabled(selectorName) {
      return this.selectorList.some(
        ({name, enabled}) => name == selectorName && enabled
      );
    },
    getSample(selector) {
      return structuredClone(SelectableObjects[selector.name]) || {};
    }
  },
  created() {
    this.panelOptions = structuredClone({
      ...defPanel().options,
      ...(this.panel.options || {})
    });
    if (!this.panel.options.selector_list) {
      // it might been used by toolbar and it is the first time... so save its new configuration
      this.saveDraft();
    }
  }
};
</script>

<style scoped>
section {
}

label:hover {
  cursor: pointer;
  opacity: 0.8;
}

section > div {
  margin-bottom: 15px;
}

label > input[type="checkbox"] {
  vertical-align: top;
  margin-right: 5px;
  margin-bottom: 15px;
}
</style>
