<template>
  <section>
    <TogglePanel title="general" persistent="toogle_history_panel_toolbar">
      <!-- <label for="">
      {{ $tc("general") }}
    </label> -->
      <div class="toggle-panel-inner">
        <div class="row">
          <div class="col-xs-8">
            <div class="form-group form-group-sm">
              <label for="">
                {{ $t("default_interval") }}
                <Tooltip :title="$t('hints.default_interval')" />
              </label>
              <div class="input-group">
                <input
                  type="number"
                  class="form-control text-center"
                  v-model="interval"
                  inc="1"
                  min="1"
                  max="24"
                />
                <div class="input-group-addon">
                  {{ $tc("hour", interval > 1 ? 2 : 1) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12" style="z-index: 2">
            <div class="form-group form-group-sm">
              <label for="" style="white-space: nowrap">
                {{ $t("samples_out_of_boundaries") }}
              </label>
              <template v-for="(item, ix) in edgesOptions">
                <div
                  v-if="item.enabled"
                  :key="ix"
                  class="clicable option"
                  :class="edges == item.value ? 'text-primary' : ''"
                  @click.stop.prevent="edges = item.value"
                >
                  <i
                    :class="
                      edges == item.value
                        ? 'fa fa-dot-circle-o'
                        : 'fa fa-circle-o'
                    "
                  ></i>
                  {{ $t(item.label) }}
                  <Tooltip :title="$t(item.hint)" />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12" style="z-index: 100">
            <div class="form-group form-group-sm">
              <label for="" style="white-space: nowrap">
                {{ $t("empty_cells") }}
              </label>
              <template v-for="(item, ix) in cellInterpolation">
                <div
                  v-if="item.enabled"
                  :key="ix * 10"
                  class="clicable option"
                  :class="missingValues == item.value ? 'text-primary' : ''"
                  @click.stop.prevent="missingValues = item.value"
                >
                  <i
                    :class="
                      missingValues == item.value
                        ? 'fa fa-dot-circle-o'
                        : 'fa fa-circle-o'
                    "
                  ></i>
                  {{ $t(item.label) }}
                  <Tooltip :title="$t(item.hint)" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </TogglePanel>
    <TogglePanel title="toolbar" persistent="toogle_history_panel_toolbar">
      <div class="toggle-panel-inner">
        <div>
          <label
            class="input-checkbox clicable"
            for="toolbarAlwaysVisible"
            @click.stop.prevent="toolbarAlwaysVisible = !toolbarAlwaysVisible"
          >
            <i
              :class="
                toolbarAlwaysVisible ? 'fa fa-check-square-o' : 'fa fa-square-o'
              "
            ></i>
            {{ $t("always_visible") }}
          </label>
        </div>
        <div v-for="group in toolbarGroupList" :key="group">
          <div
            v-if="group"
            class="toggle-title clicable"
            @click.stop.prevent="toggleToolbarGroup(group)"
          >
            <div>
              {{ $t(`titles.${group}`) }}
            </div>
            <span class="btn btn-xs">
              <i
                :class="
                  isToolbarGroupVisible(group)
                    ? 'fa fa-caret-square-o-up'
                    : 'fa fa-caret-square-o-down'
                "
              ></i>
            </span>
          </div>
          <div
            class="toolbar-group-container"
            v-if="isToolbarGroupVisible(group)"
          >
            <div
              v-for="item in toolbarFilterByGroup(group)"
              :key="item.id"
              :class="{'bg-primary': item.id == toolbarDefaultTab}"
            >
              <label
                :for="item.id"
                class="input-checkbox clicable"
                @click.stop.prevent="toggleToolbarItem(item.id)"
                :style="{color: !item.enabled ? '#888' : 'inherit'}"
              >
                <i
                  :class="
                    item.enabled ? 'fa fa-check-square-o' : 'fa fa-square-o'
                  "
                  style="min-width: 14px; font-size: 90%"
                />
                <span>
                  {{ $t(item.title) }}
                </span>
              </label>
              <!-- <div class="pull-right" v-if="group == 'tabs'"> -->
              <div
                class="clicable tab-selector"
                v-if="group == 'tabs' && item.id != toolbarDefaultTab"
              >
                <span
                  @click.stop.prevent="toolbarDefaultTab = item.id"
                  class="clicable-orange"
                >
                  <i class="fa fa-arrow-left"></i>
                  {{ $t("set_as_default") }}
                </span>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </TogglePanel>
    <TogglePanel
      :title="`${$t('chart')}`"
      persistent="toogle_history_panel_data_series"
    >
      <div class="toggle-panel-inner">
        <ChartSeriesForm
          v-model="chartSeriesConfig"
          listName="dataList"
          :checkable="true"
          :dataListParser="dataListParser"
          :types="['line', 'bar', 'scatter']"
          :symbol="false"
          :showDataSetSourceSelection="false"
          :allowStandardCurve="false"
          :requireDataList="false"
        >
          <template #before_axis>
            <ChartGeneralForm v-model="chartOptions" />
            <!-- disconnection form -->
            <DisconnectionChartForm v-model="disconnectionForm" />
            <!-- disconnection form -->
          </template>
          <template #after_axis>
            <ChartTooltipForm
              v-model="chartTooltipConfig"
              v-if="chartTooltipConfig"
            />
          </template>
        </ChartSeriesForm>
      </div>
    </TogglePanel>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import Panels from "@/assets/dashboard/panels.json";
import ChartGeneralForm from "@/components/control-sidebar/property-editors/chart-general-form.vue";
import ChartSeriesForm from "@/components/control-sidebar/property-editors/chart-series-form.vue";
import Tooltip from "@/components/tooltip.vue";
import DisconnectionChartForm from "@/components/control-sidebar/property-editors/disconnection-chart-form.vue";
import ChartTooltipForm from "@/components/control-sidebar/property-editors/chart-tooltip-form.vue";
const defaultPanelOptions = () =>
  Panels.find((panel) => panel.template.template == "EquipmentHistoryPanel")
    ?.template?.options || {};

export {defaultPanelOptions};

export default {
  name: "DetailFormHistory",
  components: {
    TogglePanel,
    ChartGeneralForm,
    ChartSeriesForm,
    Tooltip,
    DisconnectionChartForm,
    ChartTooltipForm
  },
  data() {
    return {
      edgesOptions: [
        {
          value: "leave_them_empty",
          label: "leave_them_empty",
          hint: "hints.edges_options.leave_them_empty",
          enabled: true
        },
        {
          value: "fit_in_time_window",
          label: "fit_in_time_window",
          hint: "hints.edges_options.fit_in_time_window",
          enabled: true
        }
      ],
      cellInterpolation: [
        {
          value: "leave_them_empty",
          label: "leave_them_empty",
          hint: "hints.cell_interpolation.leave_them_empty",
          enabled: true
        },
        {
          value: "last_value",
          label: "last_value",
          hint: "hints.cell_interpolation.last_value",
          enabled: true
        },
        {
          value: "linear_interpolation",
          label: "linear_interpolation",
          hint: "hints.cell_interpolation.linear_interpolation",
          enabled: true
        }
      ],
      visibleToolbarGroups: {}
    };
  },
  computed: {
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    panelOptions: {
      set(value) {
        this.panel.options = value;
      },
      get() {
        return this?.panel?.options || null;
      }
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    interval: {
      set(value) {
        let vlr = Math.abs(parseInt(value));
        this.$set(
          this.panel.options.history,
          "interval",
          -1 * (vlr < 1 ? 1 : vlr > 24 ? 24 : vlr)
        );
      },
      get() {
        let hours = -24;
        if (this?.panel?.options?.history) {
          hours =
            "interval" in this.panel.options.history
              ? this.panel.options.history.interval
              : hours;
        } else {
          hours = defaultPanelOptions()?.history?.interval || hours;
        }
        return -1 * hours;
      }
    },
    edges: {
      set(value) {
        this.$set(this.panel.options.history, "edges", value);
      },
      get() {
        return (
          this?.panel?.options?.history?.edges ||
          defaultPanelOptions()?.history?.edges ||
          "leave_them_empty"
        );
      }
    },
    missingValues: {
      set(value) {
        this.$set(this.panel.options.history, "missingValues", value);
      },
      get() {
        return (
          this?.panel?.options?.history?.missingValues ||
          defaultPanelOptions()?.history?.missingValues ||
          "leave_them_empty"
        );
      }
    },
    dataList: {
      set(values) {
        this.$set(this.panel.options, "dataList", values);
      },
      get() {
        return this?.panel?.options?.dataList || [];
      }
    },
    toolbarAlwaysVisible: {
      set(value) {
        var toolbar = {
          ...defaultPanelOptions().toolbar,
          ...JSON.parse(JSON.stringify(this?.panelOptions?.toolbar || {}))
        };
        toolbar.alwaysVisible = value;
        this.$set(this.panelOptions, "toolbar", toolbar);
      },
      get() {
        var toolbar = {
          ...defaultPanelOptions().toolbar,
          ...JSON.parse(JSON.stringify(this?.panelOptions?.toolbar || {}))
        };
        return "alwaysVisible" in toolbar ? toolbar.alwaysVisible : true;
      }
    },
    toolbarDefaultTab: {
      set(value) {
        var toolbar = {
          ...defaultPanelOptions().toolbar,
          ...JSON.parse(JSON.stringify(this?.panelOptions?.toolbar || {}))
        };
        toolbar.defaultTab = value;
        this.$set(this.panelOptions, "toolbar", toolbar);
      },
      get() {
        var toolbar = {
          ...defaultPanelOptions().toolbar,
          ...JSON.parse(JSON.stringify(this?.panelOptions?.toolbar || {}))
        };
        return "defaultTab" in toolbar ? toolbar.defaultTab : "chartTab";
      }
    },
    toolbarItems: {
      set(value) {
        this.$set(this.panelOptions.toolbar, "items", value);
      },
      get() {
        return {
          ...defaultPanelOptions().toolbar.items,
          ...JSON.parse(
            JSON.stringify(this?.panelOptions?.toolbar?.items || {})
          )
        };
      }
    },
    toolbarGroupList() {
      let groups = {};
      this.toolbarList.forEach(({group}) => {
        if (!(group in groups)) groups[group] = true;
      });
      return Object.keys(groups).sort();
    },
    toolbarList() {
      let source = defaultPanelOptions().toolbar.items;
      return Object.keys(source).map((id) => ({
        id: id,
        ...source[id],
        ...{
          enabled: this.toolbarItems[id].enabled
        }
      }));
    },
    chartSeriesConfig: {
      set(value) {
        let options = {
          ...(this?.panelOptions || {}),
          ...value,
          chartOptions: {
            ...(this?.panelOptions?.chartOptions || {}),
            ...(value?.chartOptions || {})
          }
        };
        this.panelOptions = {...this.panelOptions, ...options};
      },
      get() {
        return this.panelOptions || null;
      }
    },
    chartOptions: {
      set(value) {
        let chartOptions = {
          ...(this?.chartSeriesConfig?.chartOptions || {}),
          ...(value || {})
        };
        this.chartSeriesConfig = {
          ...this.chartSeriesConfig,
          ...{chartOptions: chartOptions}
        };
      },
      get() {
        return this?.chartSeriesConfig?.chartOptions || null;
      }
    },
    disconnectionForm: {
      set(value) {
        this.panelOptions = {...this.panelOptions, ...(value || {})};
      },
      get() {
        return this.panel.options;
      }
    },
    chartTooltipConfig: {
      set(value) {
        if (!this.panelOptions) return;
        this.panelOptions.chartOptions = this.panelOptions.chartOptions || {};
        this.$set(this.panelOptions.chartOptions, "tooltip", value);
      },
      get() {
        return this?.panelOptions?.chartOptions?.tooltip || {};
      }
    }
  },
  watch: {
    panelOptions: {
      handler(n, o) {
        if (n && o && !isEqual(n, o)) {
          this.$store.dispatch("dashboard/saveDraftPanel", {
            panel: this.panel,
            screenId: this.screenId
          });
        }
      },
      deep: true
    }
  },
  methods: {
    toolbarFilterByGroup(query) {
      return this.toolbarList.filter(({group}) => group == query);
    },
    dataListParser(lst) {
      return (lst || []).filter(
        ({id, history_enabled}) =>
          `${id}`.includes("data_group") || history_enabled
      );
    },
    toggleToolbarItem(id) {
      let toolbarItems = JSON.parse(JSON.stringify(this.toolbarItems));
      toolbarItems[id].enabled = !toolbarItems[id].enabled;
      if (
        !toolbarItems.calendarInput.enabled &&
        !toolbarItems.calendarButton.enabled
      ) {
        toolbarItems.calendarIntervalRange.enabled = false;
      }

      this.toolbarItems = toolbarItems;
    },
    toggleToolbarGroup(group) {
      this.$set(
        this.visibleToolbarGroups,
        group,
        !(this?.visibleToolbarGroups || {})[group]
      );
    },
    isToolbarGroupVisible(group) {
      return (this?.visibleToolbarGroups || {})[group] ? true : false;
    }
  }
};
</script>

<style scoped>
.box-body > .content {
  min-height: inherit;
}

.toggle-panel-inner {
  padding: 0 10px;
}

div.option {
  margin: 3px;
  white-space: nowrap;
}

div.option > i {
  margin-right: 3px;
}

.import-options {
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.import-options > span.btn {
  margin: 0 5px 0 0;
}

div.data-list {
  margin-top: -10px;
}

div.item {
  position: relative;
  width: 100%;
  font-size: 1em;
  border-top: 1px solid #e4e4e4;
  z-index: 1;
}

div.item-title {
  padding: 4px 22px 4px 2px;
  font-weight: 600;
  white-space: normal;
  width: 100%;
}

div.item-toolbar {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 4;
}

div.item-toolbar > i {
  padding: 0 4px;
  min-width: 22px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

div.toggle-title {
  position: relative;
  margin-top: 6px;
  padding: 2px 0;
}

div.toggle-title > span.btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px;
}

div.toolbar-group-container {
  margin: 5px 0 0 0px;
}

div.toolbar-group-container label {
  margin: 3px 0;
  padding-left: 4px;
}

div.toolbar-group-container > div {
  position: relative;
}

div.toolbar-group-container > div > div.tab-selector {
  position: absolute;
  right: 0;
  top: 2px;
  width: 120px;
  font-size: 9pt;
  color: transparent;
}

div.toolbar-group-container > div:hover > div.tab-selector {
  cursor: pointer;
  color: #2a6599;
}
</style>
