<template>
  <div
    v-if="asButton && equipmentData"
    class="btn"
    :class="simulation ? 'text-orange' : ''"
    @click.stop.prevent="toggle()"
    :title="tooltip"
  >
    <i class="fa fa-history hidden-xs"></i>
  </div>
  <div
    class="form-group form-group-sm"
    v-else-if="equipmentData"
    :title="tooltip"
  >
    <div
      class="small clicable"
      style="padding: 0px 5px 5px 2px; color: #999"
      v-if="showConnector && connector"
      :title="`${connector.id} ${connector.name}`"
      @click.stop.prevent="showConnector = !showConnector"
    >
      <i class="fa fa-plug"></i> {{ connector.name }}
    </div>
    <div class="input-group">
      <div
        class="input-group-addon clicable"
        style="padding: 0 4px; font-size: 80%; color: #666"
        :title="`${connector && connector.id} ${connector && connector.name}`"
        @click.stop.prevent="showConnector = !showConnector"
      >
        <i class="fa fa-plug" v-if="!showConnector"></i>
        {{ $t("source") }}
      </div>
      <input
        type="text"
        class="form-control"
        disabled
        v-model="equipmentData.name"
      />
      <div
        class="input-group-addon btn"
        :class="simulation ? 'text-orange' : ''"
        v-on:click.stop.prevent="toggle()"
        :title="$t('simulation')"
      >
        <i class="fa fa-history hidden-xs"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataSimulationToggle",
  props: {
    equipmentData: {
      type: Object,
      required: false,
      default: () => null
    },
    namedQuery: {
      type: String,
      required: false,
      default: ""
    },
    asButton: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      showConnector: true
    };
  },
  computed: {
    simulation() {
      if (!this.equipmentData) return false;
      let entries =
        this.$store.getters[`${this.namedQuery || "history"}/entries`] || {};
      return ((entries[this.equipmentData.id] || {})?.samples || []).length > 0;
    },
    connector() {
      return (
        (this.equipmentData &&
          (this.$store.getters["dashboard/connectorList"] || []).find(
            ({id}) => id == this.equipmentData.clp_id
          )) ||
        null
      );
    },
    tooltip() {
      return `${this.$t("simulation")}\nConnector: #${this?.connector?.id} ${
        this?.connector?.name
      }\nData: #${this?.equipmentData?.id} ${this?.equipmentData?.name}`;
    }
  },
  methods: {
    toggle() {
      this.$store.dispatch(
        `${this.namedQuery || "history"}/${
          this.simulation ? "resetData" : "simulate"
        }`,
        this.equipmentData.id
      );
    }
  }
};
</script>

<style scoped>
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}
</style>
