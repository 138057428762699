<template>
  <div>
    <TogglePanel
      class="toggle-panel"
      :title="$tc(`titles.title`, 1)"
      :hint="$tc(`hints.title`, 1)"
      layout="compressed"
      accordion="yaxis_conf"
    >
      <ChartAxisTitleForm
        v-if="form"
        :nameLocationCfgOptions="nameLocationCfgOptions"
        :defaultLocationIndex="0"
        v-model="axisTitle"
      />
    </TogglePanel>
    <TogglePanel
      class="toggle-panel"
      :title="$tc(`titles.scale`, 1)"
      :hint="$tc(`hints.scale`, 1)"
      layout="compressed"
      accordion="yaxis_conf"
    >
      <ChartAxisScaleForm v-model="axisScale" />
      <div class="form-group form-group-sm">
        <label for="chkVisible" class="checkbox-inline" style="font-weight:600">
          <input type="checkbox" id="chkVisible" v-model="axisEnabled" />
          <span style="margin-left:10px">
            {{ $tc("visible", 2) }}
          </span>
        </label>
      </div>
      <div class="form-group form-group-sm">
        <label for="chkInverse" class="checkbox-inline" style="font-weight:600">
          <input type="checkbox" id="chkInverse" v-model="inverse" />
          <span style="margin-left:10px">
            {{ $tc("titles.inverse", 2) }}
          </span>
        </label>
      </div>
      <div class="form-group form-group-sm">
        <label
          for=""
          class="clicable"
          :title="`${$t('side')} (${$tc(axisSide, 1).toLowerCase()})`"
          @click.stop.prevent="axisSide = axisSide == 'left' ? 'right' : 'left'"
          style="font-weight:600"
        >
          <i
            class="fa fa-area-chart text-primary"
            :style="
              `transform: scaleX(${
                axisSide == 'right' ? -1 : 1
              });margin: 0 3px 0 0;padding: 2px; border: 1px solid lightgray; border-left: 3px solid #337ab7;`
            "
          ></i>
          {{
            `${$t("side")} ${$tc(
              "horizontal_choice",
              axisSide == "left" ? 1 : 2
            ).toLowerCase()}`
          }}
        </label>
      </div>
    </TogglePanel>
    <TogglePanel
      class="toggle-panel"
      :title="$tc(`titles.label`, 1)"
      :hint="$t(`hints.label`)"
      layout="compressed"
      accordion="yaxis_conf"
    >
      <div class="row">
        <div class="col-xs-12">
          <FontSelector
            v-model="axisFont"
            :noTextDecoration="true"
            :allowedUnits="['%']"
            :editable="axisCustomFont"
          />
        </div>
      </div>
      <!-- end label font -->
      <!-- begin axis color and format -->
      <div class="row">
        <div class="col-xs-12">
          <div class="form-group form-group-sm">
            <label for="label_format"
              >{{ $tc("color", 1) }} {{ $t("and") }} {{ $t("format") }}
            </label>
            <div class="input-group">
              <div class="input-group-addon btn addon-btn no-padding">
                <ColorPicker
                  class="color-picker"
                  :pickerStyle="{left: '-80px'}"
                  v-model="axisColor"
                  :title="`${$tc('color', 1)}`"
                />
              </div>
              <select class="form-control" v-model="type">
                <option value="value">{{ $t("default") }}</option>
                <option value="text_list">
                  {{ $tc("text_list", 1) }}
                </option>
                <option value="expression">{{ $t("customized") }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type !== 'value'" class="custom-format-form">
        <DataStateListForm
          v-if="type == 'text_list'"
          :hasDefault="false"
          :hasColor="false"
          v-model="textList"
        />
        <div class="expression-box" v-else>
          <div>
            <div class="form-group form-group-sm">
              <label>{{ $t("expression") }} </label>
              <JSONPathPicker
                v-model="axisExpression"
                :entry="expressionEntry"
              />
            </div>
            <div class="form-group form-group-sm">
              <label class="clicable">{{ $t("format") }} </label>
              <input type="text" class="form-control" v-model="axisFormat" />
            </div>
          </div>
        </div>
      </div>
      <!-- end axis color and format -->
      <div class="row">
        <div class="col-xs-4">
          <label for="axisDistance">{{ $t("position") }}</label>
          <div class="form-group form-group-sm no-select">
            <div class="input-group">
              <div
                class="input-group-addon btn"
                @click="axisInside = !axisInside"
                :title="
                  `${$tc('titles.related_to', 2, {
                    position: $t(axisInside ? 'inside' : 'outside'),
                    reference: $tc('line', 1)
                  })}`
                "
              >
                <span
                  class="fa fa-step-backward clicable"
                  :style="`transform: rotate(${axisInside ? 0 : 180}deg);`"
                ></span>
              </div>
              <input
                type="number"
                :title="
                  `${$tc('titles.related_to', 2, {
                    position: $t('titles.distance'),
                    reference: $tc('line', 1)
                  })}`
                "
                id="axisDistance"
                v-model="axisDistance"
                class="form-control no-padding text-center"
              />
            </div>
          </div>
        </div>
        <div class="col-xs-8">
          <label for="axisRotation" style="width: 100%; position: relative">
            {{ $t("synoptic.rotation") }}: {{ axisRotation }}°
            <span
              class="clicable"
              :title="$t('reset')"
              style="position: absolute; right: 0; top: 2px; font-size: 80%"
              @click="axisRotation = 0"
            >
              <i class="fa fa-undo"></i>
            </span>
          </label>
          <div class="form-group form-group-sm no-select">
            <input
              style="padding: 0 5px"
              type="range"
              min="-90"
              max="90"
              v-model="axisRotation"
              id="axisRotation"
            />
          </div>
        </div>
      </div>
    </TogglePanel>
  </div>
</template>

<script>
import {isEqual} from "lodash";
import FontSelector from "@/components/control-sidebar/property-editors/font-selector.vue";
import ColorPicker from "@/components/editor/color-picker.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import DataStateListForm from "@/components/control-sidebar/property-editors/data-state-list-form.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ChartAxisScaleForm from "@/components/control-sidebar/property-editors/chart-axis-scale-form.vue";
import ChartAxisTitleForm from "@/components/control-sidebar/property-editors/chart-axis-title-form.vue";
import {axisProp} from "@/components/control-sidebar/property-editors/chart-axis-scale-form.vue";
import messages from "@/i18n/chart.js";
// no custom
const dftAxisLabel = () => ({
  color: "#000",
  fontSize: 14,
  fontWeight: 300
});

const dftYAxis = (opt) => ({
  type: "value",
  name: "",
  offset: opt?.offset ?? "",
  position: opt?.position ?? "left",
  nameLocation: "end",
  nameGap: 15,
  nameRotate: 0,
  // nameTextStyle: {
  //   color: "#000",
  //   backgroundColor: "#fff",
  //   fontSize: 14,
  //   fontWeight: 300,
  //   verticalAlign: "top",
  //   align: "left",
  //   borderColor: "rgba(177, 177, 177, 1)",
  //   borderWidth: 0.5,
  //   padding: [5, 8, 4, 8]
  // },
  axisLabel: dftAxisLabel(),
  min: "",
  max: "",
  interval: "",
  inverse: false
});

const dftCustomLabelFont = () => ({
  fontFamily: "Source Sans Pro",
  fontSize: "84%",
  fontStyle: "normal",
  fontWeight: "300"
});

const dftCustomAxisLabel = () => ({
  customFont: false,
  show: true,
  color: "#000",
  formatter: null,
  inside: false,
  rotate: 0,
  margin: 8,
  ...dftCustomLabelFont()
});

export {dftYAxis, axisProp};
export default {
  name: "ChartYAxisForm",
  i18n: {messages},
  components: {
    FontSelector,
    ColorPicker,
    JSONPathPicker,
    DataStateListForm,
    TogglePanel,
    ChartAxisScaleForm,
    ChartAxisTitleForm
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: null
    },
    namedQuery: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      form: null,
      custom: false,
      axisLabelRotation: 0,
      axisLabelDistance: 8,
      expression: "",
      format: "",
      collapseExpression: false,
      nameLocationCfg: {
        index: 0,
        options: ["end", "middle", "start"]
      }
    };
  },
  computed: {
    axisCustomFont() {
      return (this?.form?.axisLabel || {})?.customFont || false;
    },
    scale() {
      return "yScale";
    },
    expressionEntry() {
      return {
        minimum: this.axisProp("min"),
        maximum: this.axisProp("max"),
        interval: this.axisProp("interval"),
        value: ""
      };
    },
    list() {
      return this.textList || [];
    },
    axisEnabled: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis.show = value;
        this.setForm(axis);
      },
      get() {
        return this?.form?.show ?? true;
      }
    },
    axisSide: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis.position = value;
        this.setForm(axis);
      },
      get() {
        return this?.form?.position || "left";
      }
    },
    axisFont: {
      set(value) {
        if (!this.form) return;
        let label = structuredClone((this.form || {})?.axisLabel || {});
        let color = label.color;
        if (value) {
          if (!label.customFont) {
            label = {
              ...dftCustomAxisLabel(),
              ...{customFont: true, color: color}
            };
          } else {
            label.fontFamily = value["font-family"];
            label.fontSize = `${value["font-size"]}`.replace(/\D/g, "") + "%";
            label.fontWeight = value["font-weight"];
            label.fontStyle = value["font-style"];
          }
        } else {
          if (!label.customFont) return;
          label = this.resetLabelFont(label);
          label.color = color;
        }
        let axis = {
          ...(this.form || {}),
          ...{axisLabel: label}
        };
        this.setForm(axis);
      },
      get() {
        let label = this?.form?.axisLabel || {};
        let dft = dftCustomLabelFont();
        return {
          "font-family": label.fontFamily ?? dft.fontFamily,
          "font-size": label.fontSize ?? dft.fontSize,
          "font-style": label.fontStyle ?? dft.fontStyle,
          "font-weight": label.fontWeight ?? dft.fontWeight
        };
      }
    },
    axisColor: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis.axisLabel = {...axis.axisLabel, ...{color: value}};
        this.setForm(axis);
      },
      get() {
        return this?.form?.axisLabel?.color || "#333";
      }
    },
    axisInside: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis.axisLabel = {...axis.axisLabel, ...{inside: value}};
        this.setForm(axis);
      },
      get() {
        return this?.form?.axisLabel?.inside || false;
      }
    },
    axisRotation: {
      set(value) {
        if (!this.form) return;
        this.axisLabelRotation = value;
        let axis = {...this.form};
        axis.axisLabel = {
          ...axis.axisLabel,
          ...{rotate: this.axisLabelRotation || 0}
        };
        this.setForm(axis);
      },
      get() {
        return this.axisLabelRotation || 0;
      }
    },
    axisDistance: {
      set(value) {
        if (!this.form) return;
        this.axisLabelDistance = value;
        let axis = {...this.form};
        axis.axisLabel = {
          ...axis.axisLabel,
          ...{margin: Number(this.axisLabelDistance || 0)}
        };
        this.setForm(axis);
      },
      get() {
        return this.axisLabelDistance;
      }
    },
    type: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis[this.scale] = {...(axis[this.scale] || {})};
        axis[this.scale].type = value;
        if (value == "text_list") {
          axis[this.scale].expression = "";
          this.expression = "";
          this.format = "";
        } else if (value == "expression") {
          axis[this.scale].textList = null;
          this.expression = "$value";
          this.format = "%d";
        } else {
          axis[this.scale].expression = "";
          axis[this.scale].textList = null;
          this.expression = "";
          this.format = "";
        }
        this.setForm(this.injectExpression(axis));
      },
      get() {
        return (this?.form || {})[this.scale]?.type || "value";
      }
    },
    textList: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis[this.scale] = {...(axis[this.scale] || {})};
        axis[this.scale].textList = value;
        this.setForm(axis);
      },
      get() {
        return (this?.form || {})[this.scale]?.textList || null;
      }
    },
    axisExpression: {
      set(value) {
        if (!this.form) return;
        this.expression = value;
        this.setForm(this.injectExpression({...this.form}));
      },
      get() {
        return this.expression;
      }
    },
    axisFormat: {
      set(value) {
        if (!this.form) return;
        this.format = value;
        this.setForm(this.injectExpression({...this.form}));
      },
      get() {
        return this.format;
      }
    },
    inverse: {
      set(value) {
        if (!this.form) return;
        this.axisProp("inverse", value);
      },
      get() {
        return this.axisProp("inverse") ?? false ? true : false;
      }
    },
    axisScale: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form, ...(value || {})};
        this.setForm(form);
      },
      get() {
        let entry = {
          min: this.axisProp("min"),
          max: this.axisProp("max"),
          interval: this.axisProp("interval"),
          offset: this.axisProp("offset")
        };
        return entry;
      }
    },
    axisTitle: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form, ...(value || {})};
        this.setForm(form);
      },
      get() {
        return this.form;
      }
    },
    nameLocationCfgOptions() {
      return this.inverse
        ? [
            {
              id: "end",
              icon: "glyphicon glyphicon-object-align-bottom",
              title: this.$t("bottom")
            },
            {
              id: "middle",
              icon: "glyphicon glyphicon-object-align-horizontal",
              title: this.$t("middle"),
              rotate: 90
            },
            {
              id: "start",
              icon: "glyphicon glyphicon-object-align-top",
              title: this.$t("top")
            }
          ]
        : [
            {
              id: "end",
              icon: "glyphicon glyphicon-object-align-top",
              title: this.$t("top")
            },
            {
              id: "middle",
              icon: "glyphicon glyphicon-object-align-horizontal",
              title: this.$t("middle"),
              rotate: 90
            },
            {
              id: "start",
              icon: "glyphicon glyphicon-object-align-bottom",
              title: this.$t("bottom")
            }
          ];
    }
  },
  methods: {
    injectExpression(axis) {
      axis[this.scale] = {...(axis[this.scale] || {})};
      let v1 = this.$utils.trim(this.expression);
      let v2 = this.$utils.trim(this.format);
      if (v1 !== "" || v2 !== "") {
        axis[this.scale].expression = `${v1}|${v2}`;
      } else {
        axis[this.scale].expression = "";
      }
      return axis;
    },
    setForm(payload) {
      if (payload && this.form && !isEqual(this.form, payload)) {
        this.$set(this, "form", payload);
        this.$emit("input", this.form);
      }
    },
    axisProp(name, value) {
      return axisProp(this, this.form, name, value);
    },
    focus() {
      if (this.$refs.axisName) {
        this.$refs.axisName.focus();
      }
    },
    resetLabelFont(label) {
      delete label.fontFamily;
      delete label.fontSize;
      delete label.fontWeight;
      delete label.fontStyle;
      label.customFont = false;
      label = {...label, ...dftAxisLabel()};
      return label;
    },
    init() {
      const _initLocal = (name, value, dft) => {
        this[name] = (value ?? "") === "" ? dft : value;
      };
      let form = structuredClone(this.value || {});
      let label = dftCustomAxisLabel();
      if (!form.axisLabel.customFont) {
        label = this.resetLabelFont(label);
      }
      form.axisLabel = {...label, ...(form?.axisLabel || {})};
      this.form = form;

      let exp = (
        ((this?.form || {})[this.scale]?.expression ?? "") ||
        ""
      ).split("|");
      _initLocal("expression", exp[0], "");
      _initLocal("format", exp[1], "");
      _initLocal("axisLabelRotation", this?.form?.axisLabel?.rotate, 0);
      _initLocal("axisLabelDistance", this?.form?.axisLabel?.margin, 8);
    }
  },
  created() {
    this.init();
  }
};
</script>

<style scoped>
.inline-field {
  position: relative;
  display: inline-block;
}

.inline-field > input {
  z-index: 0;
  background-color: transparent;
  text-align: center;
  padding: 8px 0 0 0;
}

.inline-field > label {
  position: absolute;
  top: -2px;
  left: 3px;
  z-index: 1;
  font-size: 8pt;
  font-weight: normal;
  margin: 0;
  color: #777;
}

.scope-block {
  padding-bottom: 5px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dfdfdf;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.toggle-panel {
  margin: 0px 5px 15px -15px;
  padding: 0 0 0 10px;
}

.toggle-panel::v-deep > .compressed-body-opened {
  padding: 10px 15px;
}
.small-addon {
  font-size: 8pt;
  font-weight: normal;
  margin: 0;
  color: #777;
}

.skin-dark .small-addon {
  color: #b8c7ce;
}
</style>
