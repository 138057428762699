var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.entry)?_c('section',[_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$tc("titles.named_query", 1))+" "),_c('Tooltip',{attrs:{"title":_vm.$t('hints.named_query')}})],1),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.entryName),expression:"entryName"}],staticClass:"form-control",attrs:{"type":"text","title":_vm.entryName,"placeholder":_vm.$t('name')},domProps:{"value":(_vm.entryName)},on:{"input":function($event){if($event.target.composing)return;_vm.entryName=$event.target.value}}}),_c('span',{staticClass:"input-group-addon btn",attrs:{"title":_vm.$t('copy', {item: _vm.entryName}),"disabled":_vm.entryName == ''},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyToClipboard()}}},[_c('i',{staticClass:"fa fa-copy text-warning"})]),_c('span',{staticClass:"input-group-addon btn",attrs:{"title":_vm.$t('close')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('cancel')}}},[_c('i',{staticClass:"fa fa-close"})])])]),_c('div',{staticStyle:{"border":"1px solid lightgray","border-radius":"3px","padding":"3px"}},[_c('div',{staticClass:"form-group form-group-sm"},[_c('div',{staticStyle:{"position":"relative"}},[_c('label',{attrs:{"for":""}},[_c('i',{staticClass:"fa fa-key"}),_vm._v(" "+_vm._s(_vm.$t("titles.group_by_expression"))+" "),_c('Tooltip',{attrs:{"title":_vm.$t('hints.group_by_expression')}})],1),(_vm.groupByExpression)?_c('span',{staticStyle:{"position":"absolute","right":"2px","font-size":"85%"},attrs:{"title":_vm.$tc(_vm.groupByOrder == 'asc' ? 'ascendent' : 'descendent')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.groupByOrder = _vm.groupByOrder == 'asc' ? 'desc' : 'asc'}}},[_c('i',{class:_vm.groupByOrder == 'asc'
                ? 'fa clicable fa-sort-alpha-asc text-primary'
                : 'fa clicable fa-sort-alpha-desc text-danger'})]):_vm._e()]),_c('JSONPathPicker',{attrs:{"entry":_vm.defGroupByEntry,"append":true,"entryType":""},model:{value:(_vm.groupByExpression),callback:function ($$v) {_vm.groupByExpression=$$v},expression:"groupByExpression"}})],1),_c('div',{staticClass:"form-group form-group-sm"},[_c('div',{staticStyle:{"position":"relative"}},[_c('label',{attrs:{"for":""}},[_c('i',{staticClass:"fa fa-filter"}),_vm._v(" "+_vm._s(_vm.$tc("filter", 1))+" "),_c('Tooltip',{attrs:{"title":_vm.$t('hints.filter_expression')}})],1)]),_c('JSONPathPicker',{attrs:{"entry":_vm.defGroupByEntry,"append":true,"entryType":"","placeholder":"value > 50"},model:{value:(_vm.filterExpression),callback:function ($$v) {_vm.filterExpression=$$v},expression:"filterExpression"}})],1),_c('div',{staticClass:"columns-container"},[_c('DataListForm',{attrs:{"addNewText":"add","labelSelection":_vm.$t('columns'),"selectable":false,"collapsed":false,"itemsCollapsed":true,"dataListParser":_vm.dataListParser,"draggable":false,"showAddAllDataButton":false,"allowDupplicated":true,"dataSelectionOnTop":false},on:{"item:added":_vm.onNewColumn},scopedSlots:_vm._u([{key:"item",fn:function(entry){return [_c('div',{staticClass:"column-form"},[_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("titles.column_name")))]),_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.getDataNameAt(entry.item.index)},domProps:{"value":_vm.dataItemProperty(entry.item, 'columnName')},on:{"input":function($event){return _vm.dataItemProperty(
                      entry.item,
                      'columnName',
                      $event.target.value
                    )}}}),_c('div',{staticClass:"input-group-addon btn",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onResetItem($event, 'columnName', entry.item)}}},[_c('i',{staticClass:"fa fa-close"})])]),_c('div',{staticClass:"small text-warning",attrs:{"title":_vm.getDataTitleAt(entry.item.index)}},[_c('i',{staticClass:"glyphicon glyphicon-stats",staticStyle:{"font-size":"70%:cursor:help"}}),_c('span',{staticStyle:{"vertical-align":"text-top"}},[_vm._v(" "+_vm._s(_vm.getDataNameAt(entry.item.index))+" ")])])]),_c('div',{staticClass:"form-group form-group-sm"},[_c('label',[_vm._v(_vm._s(_vm.$t("titles.aggregation_function"))+" "),_c('Tooltip',{attrs:{"title":_vm.$t('hints.aggregation_function')}})],1),_c('div',{staticClass:"input-group"},[_c('select',{staticClass:"form-control",domProps:{"value":_vm.dataItemProperty(entry.item, 'aggregationFunctionName')},on:{"input":function($event){return _vm.dataItemProperty(
                      entry.item,
                      'aggregationFunctionName',
                      $event.target.value
                    )}}},[_c('option',{attrs:{"value":""}},[_vm._v(_vm._s(_vm.$tc("none", 1)))]),_vm._l((_vm.functionList),function(functionName,ix){return _c('option',{key:ix,domProps:{"value":functionName}},[_vm._v(" "+_vm._s(functionName)+" ")])})],2),_c('div',{staticClass:"input-group-addon btn",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onResetItem($event, 'aggregationFunctionName', entry.item)}}},[_c('i',{staticClass:"fa fa-close"})])])]),_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("format"))+" "),_c('Tooltip',{attrs:{"title":_vm.$t('custom_format')}})],1),_c('div',{staticClass:"input-group"},[_c('input',{staticClass:"form-control",attrs:{"type":"text"},domProps:{"value":_vm.dataItemProperty(entry.item, 'columnFormat')},on:{"input":function($event){return _vm.dataItemProperty(
                      entry.item,
                      'columnFormat',
                      $event.target.value
                    )}}}),_c('div',{staticClass:"input-group-addon btn",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onResetItem($event, 'columnFormat', entry.item)}}},[_c('i',{staticClass:"fa fa-close"})])])])])]}},{key:"info",fn:function(){return [_c('div')]},proxy:true}],null,false,2486786659),model:{value:(_vm.entry.dataList),callback:function ($$v) {_vm.$set(_vm.entry, "dataList", $$v)},expression:"entry.dataList"}})],1)]),_c('div',{staticClass:"footer"},[_c('span',{staticClass:"btn btn-xs btn-default pull-left",attrs:{"title":_vm.$t('remove')},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.remove.apply(null, arguments)}}},[_c('span',{},[_c('i',{staticClass:"fa fa-trash"}),_vm._v(" "+_vm._s(_vm.$t("remove"))+" ")])]),_c('span',{staticClass:"pull-right"},[_c('span',{staticClass:"btn btn-xs btn-primary",staticStyle:{"margin-left":"10px"},attrs:{"disabled":!_vm.isValid},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.save(true)}}},[_c('span',[_c('i',{staticClass:"fa fa-check"}),_vm._v(" "+_vm._s(_vm.$t("save")))])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }