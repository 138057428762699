<template>
  <div>
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group form-group-sm no-select">
          <label class="checkbox-inline">
            <input type="checkbox" v-model="customAxis" />
            {{ $tc("customized", 2) }}
          </label>
        </div>
      </div>
    </div>
    <template v-if="customAxis">
      <div class="row" v-if="allowTypeSelection">
        <div class="col-xs-12">
          <div class="form-group form-group-sm">
            <label for="axisType">
              {{ $tc("titles.axis_type", 1) }}
              <Tooltip :title="$t('hints.axis_type')"></Tooltip>
            </label>
            <select
              name="axisType"
              id="axisType"
              v-model="axisType"
              class="form-control"
            >
              <option
                v-for="item in axisTypeOptions"
                :value="item.id"
                :key="item.id"
              >
                {{ item.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <TogglePanel
        class="toggle-panel"
        :title="$tc(`titles.title`, 1)"
        :hint="$tc(`hints.title`, 1)"
        layout="compressed"
        accordion="yaxis_conf"
      >
        <ChartAxisTitleForm
          v-if="form"
          :nameLocationCfgOptions="nameLocationCfgOptions"
          :defaultLocationIndex="1"
          v-model="axisTitle"
        />
      </TogglePanel>
      <TogglePanel
        class="toggle-panel"
        :title="$tc(`titles.scale`, 1)"
        :hint="$tc(`hints.scale`, 1)"
        layout="compressed"
        accordion="xaxis_conf"
        v-if="axisType == 'value'"
      >
        <ChartAxisScaleForm v-model="axisScale" />
        <div class="row">
          <div class="col-xs-12">
            <div class="form-group form-group-sm">
              <label for="dataSerieId">{{ $t("data_series") }}</label>
              <select
                name="dataSerieId"
                id="dataSerieId"
                class="form-control"
                v-model="dataSerieId"
              >
                <option value="">{{ $tc("none", 1) }}</option>
                <option
                  :value="item.id"
                  :key="item.id"
                  v-for="item in filteredSeries"
                  >{{ item.chartOptions.name }}</option
                >
              </select>
            </div>
          </div>
        </div>
      </TogglePanel>
      <TogglePanel
        class="toggle-panel"
        :title="$tc(`titles.label`, 1)"
        :hint="$t(`hints.label`)"
        layout="compressed"
        accordion="xaxis_conf"
      >
        <div>
          <slot name="before"> </slot>

          <!-- begin label font -->
          <div class="row">
            <div class="col-xs-12">
              <FontSelector
                v-model="axisFont"
                :noTextDecoration="true"
                :allowedUnits="['%']"
                :editable="axisCustomFont"
              />
            </div>
          </div>
          <!-- end label font -->
          <!-- begin axis color and format -->
          <div class="row">
            <div class="form-group form-group-sm col-xs-12">
              <label for="label_format"
                >{{ $tc("color", 1) }} {{ $t("and") }} {{ $t("format") }}
              </label>
              <div class="input-group">
                <div class="input-group-addon btn addon-btn no-padding">
                  <ColorPicker
                    class="color-picker"
                    :pickerStyle="{left: '-80px'}"
                    v-model="axisColor"
                    :title="`${$tc('color', 1)}`"
                  />
                </div>
                <select class="form-control" v-model="axisScaleType">
                  <option value="value">{{ $t("default") }}</option>
                  <option value="expression">{{ $t("customized") }}</option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="axisScaleType !== 'value'" class="custom-format-form">
            <DataStateListForm
              v-if="axisScaleType == 'text_list'"
              :hasDefault="false"
              :hasColor="false"
              v-model="textList"
            />
            <div class="expression-box" v-else>
              <div>
                <div class="form-group form-group-sm">
                  <label>{{ $t("expression") }} </label>
                  <JSONPathPicker
                    v-model="axisExpression"
                    :entry="expressionEntry"
                  />
                </div>
                <div class="form-group form-group-sm">
                  <label class="clicable">{{ $t("format") }} </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="axisFormat"
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- end axis color and format -->
          <div class="row">
            <div class="col-xs-4">
              <label for="axisDistance">{{ $t("position") }}</label>
              <div class="form-group form-group-sm no-select">
                <div class="input-group">
                  <div
                    class="input-group-addon btn"
                    @click="axisInside = !axisInside"
                    :title="
                      `${$tc('titles.related_to', 2, {
                        position:
                          propName == 'xAxis'
                            ? $t(axisInside ? 'top' : 'bottom')
                            : $t(axisInside ? 'inside' : 'outside'),
                        reference: $tc('line', 1)
                      })}`
                    "
                  >
                    <span
                      class="fa fa-step-backward clicable"
                      :style="
                        `transform: rotate(${
                          propName == 'xAxis'
                            ? axisInside
                              ? 270
                              : 90
                            : axisInside
                            ? 0
                            : 180
                        }deg);`
                      "
                    ></span>
                  </div>
                  <input
                    type="number"
                    :title="
                      `${$tc('titles.related_to', 2, {
                        position: $t('titles.distance'),
                        reference: $tc('line', 1)
                      })}`
                    "
                    id="axisDistance"
                    v-model="axisDistance"
                    class="form-control no-padding text-center"
                  />
                </div>
              </div>
            </div>
            <div class="col-xs-8">
              <label for="axisRotation" style="width: 100%; position: relative">
                {{ $t("synoptic.rotation") }}: {{ axisRotation }}°
                <span
                  class="clicable"
                  :title="$t('reset')"
                  style="position: absolute; right: 0; top: 2px; font-size: 80%"
                  @click="axisRotation = 0"
                >
                  <i class="fa fa-undo"></i>
                </span>
              </label>
              <div class="form-group form-group-sm no-select">
                <input
                  style="padding: 0 5px"
                  type="range"
                  min="-90"
                  max="90"
                  v-model="axisRotation"
                  id="axisRotation"
                />
              </div>
            </div>
          </div>
          <slot name="after"> </slot>
        </div>
      </TogglePanel>
    </template>
  </div>
</template>

<script>
import {isEqual} from "lodash";
import FontSelector from "@/components/control-sidebar/property-editors/font-selector.vue";
import ColorPicker from "@/components/editor/color-picker.vue";
import JSONPathPicker from "@/components/control-sidebar/property-editors/json-path-picker.vue";
import DataStateListForm from "@/components/control-sidebar/property-editors/data-state-list-form.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ChartAxisScaleForm from "@/components/control-sidebar/property-editors/chart-axis-scale-form.vue";
import ChartAxisTitleForm from "@/components/control-sidebar/property-editors/chart-axis-title-form.vue";
import Tooltip from "@/components/tooltip.vue";
import messages from "@/i18n/chart.js";
import {
  axisProp,
  resetScale
} from "@/components/control-sidebar/property-editors/chart-axis-scale-form.vue";

const dftXAxis = () => ({
  type: "time",
  show: true,
  boundaryGap: true,
  axisLabel: {
    show: true,
    color: "#000",
    fontSize: 14,
    fontWeight: 300,
    formatter: {
      year: "{yyyy}",
      month: "{MMM}",
      day: "{dd}/{MM}",
      hour: "{HH}:{mm}",
      minute: "{HH}:{mm}",
      second: "{HH}:{mm}:{ss}",
      millisecond: "{hh}:{mm}:{ss} {SSS}",
      none: "{yyyy}-{MM}-{dd} {hh}:{mm}:{ss} {SSS}"
    }
  },
  xScale: {
    type: "value",
    expression: "",
    textList: null
  }
});

const dftCustomLabelFont = () => ({
  fontFamily: "Source Sans Pro",
  fontSize: "84%",
  fontStyle: "normal",
  fontWeight: "normal"
});

const dftCustomAxisLabel = () => ({
  customFont: false,
  show: true,
  color: "#000",
  formatter: null,
  inside: false,
  rotate: 0,
  margin: 8,
  ...dftCustomLabelFont()
});

const dftCustomXAxisLabel = (namedQuery) => ({
  ...dftCustomAxisLabel(),
  ...dftCustomLabelFont(),
  formatter: namedQuery ? "$value" : 'moment($value).format("DD/MM/YY HH:mm")'
});

const dftCustomXAxis = (namedQuery) => ({
  ...dftXAxis(),
  ...{
    axisLabel: dftCustomXAxisLabel(namedQuery)
  }
});

export default {
  name: "ChartAxisXForm",
  i18n: {messages},
  components: {
    FontSelector,
    ColorPicker,
    JSONPathPicker,
    DataStateListForm,
    TogglePanel,
    ChartAxisScaleForm,
    ChartAxisTitleForm,
    Tooltip
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    namedQuery: {
      type: String,
      required: false,
      default: ""
    },
    series: {
      type: Array,
      required: true,
      default: () => []
    },
    allowTypeSelection: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      form: null,
      propName: "xAxis",
      custom: false,
      axisLabelRotation: 0,
      axisLabelDistance: 8,
      expression: "",
      format: "",
      collapseExpression: false,
      nameLocationCfg: {
        index: 1,
        options: ["start", "middle", "end"]
      }
    };
  },
  computed: {
    axisCustomFont() {
      return (this?.form?.axisLabel || {})?.customFont || false;
    },
    scale() {
      return "xScale";
    },
    expressionEntry() {
      return {
        // minimum: this.yAxleProp("min"),
        // maximum: this.yAxleProp("max"),
        // interval: this.yAxleProp("interval"),
        value: ""
      };
    },
    customAxis: {
      set(value) {
        if (!this.form) return;
        let axis;
        this.custom = value;
        if (value) {
          let label =
            this.propName == "xAxis"
              ? dftCustomXAxisLabel(this.namedQuery)
              : dftCustomAxisLabel();
          if (value) {
            this.axisLabelDistance = label.margin;
          } else {
            delete label.fontFamily;
            delete label.fontSize;
            delete label.fontWeight;
            delete label.fontStyle;
            label.formatter = null;
          }
          axis = {
            ...dftCustomXAxis(this.namedQuery),
            axisLabel: label
          };
        } else {
          axis = dftXAxis();
        }
        this.setForm(axis);
      },
      get() {
        return this.custom;
      }
    },
    axisFont: {
      set(value) {
        if (!this.form) return;
        let label = structuredClone((this.form || {})?.axisLabel || {});
        if (value) {
          if (!label.customFont) {
            label = {
              ...(this.propName == "xAxis"
                ? dftCustomXAxisLabel(this.namedQuery)
                : dftCustomAxisLabel()),
              ...label,
              ...{customFont: true}
            };
          } else {
            label.fontFamily = value["font-family"];
            label.fontSize = `${value["font-size"]}`.replace(/\D/g, "") + "%";
            label.fontWeight = value["font-weight"];
            label.fontStyle = value["font-style"];
          }
        } else {
          delete label.fontFamily;
          delete label.fontSize;
          delete label.fontWeight;
          delete label.fontStyle;
          label.customFont = false;
        }
        let axis = {
          ...(this.form || {}),
          ...{axisLabel: label}
        };
        this.setForm(axis);
      },
      get() {
        let label = this?.form?.axisLabel || {};
        let dft = dftCustomLabelFont();
        return {
          "font-family": label.fontFamily ?? dft.fontFamily,
          "font-size": label.fontSize ?? dft.fontSize,
          "font-style": label.fontStyle ?? dft.fontStyle,
          "font-weight": label.fontWeight ?? dft.fontWeight
        };
      }
    },
    axisColor: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis.axisLabel = {...axis.axisLabel, ...{color: value}};
        this.setForm(axis);
      },
      get() {
        return this?.form?.axisLabel?.color || "#333";
      }
    },
    axisInside: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis.axisLabel = {...axis.axisLabel, ...{inside: value}};
        this.setForm(axis);
      },
      get() {
        return this?.form?.axisLabel?.inside || false;
      }
    },
    axisRotation: {
      set(value) {
        if (!this.form) return;
        this.axisLabelRotation = value;
        let axis = {...this.form};
        axis.axisLabel = {
          ...axis.axisLabel,
          ...{rotate: this.axisLabelRotation || 0}
        };
        this.setForm(axis);
      },
      get() {
        return this.axisLabelRotation || 0;
      }
    },
    axisDistance: {
      set(value) {
        if (!this.form) return;
        this.axisLabelDistance = value;
        let axis = {...this.form};
        axis.axisLabel = {
          ...axis.axisLabel,
          ...{margin: Number(this.axisLabelDistance || 0)}
        };
        this.setForm(axis);
      },
      get() {
        return this.axisLabelDistance;
      }
    },
    axisScaleType: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis[this.scale] = {...(axis[this.scale] || {})};
        axis[this.scale].type = value;
        if (value == "text_list") {
          axis[this.scale].expression = "";
          this.expression = "";
          this.format = "";
        } else if (value == "expression") {
          axis[this.scale].textList = null;
          this.expression = "$value";
          this.format =
            this.propName == "xAxis"
              ? this.namedQuery
                ? "%s"
                : "DD/MM HH:mm"
              : "%d";
        } else {
          axis[this.scale].expression = "";
          axis[this.scale].textList = null;
          this.expression = "";
          this.format = "";
        }
        this.setForm(this.injectExpression(axis));
      },
      get() {
        return (this?.form || {})[this.scale]?.type || "value";
      }
    },
    textList: {
      set(value) {
        if (!this.form) return;
        let axis = {...this.form};
        axis[this.scale] = {...(axis[this.scale] || {})};
        axis[this.scale].textList = value;
        this.setForm(axis);
      },
      get() {
        return (this?.form || {})[this.scale]?.textList || null;
      }
    },
    axisExpression: {
      set(value) {
        if (!this.form) return;
        this.expression = value;
        this.setForm(this.injectExpression({...this.form}));
      },
      get() {
        return this.expression;
      }
    },
    axisFormat: {
      set(value) {
        if (!this.form) return;
        this.format = value;
        this.setForm(this.injectExpression({...this.form}));
      },
      get() {
        return this.format;
      }
    },
    axisTypeOptions() {
      let lst = [
        {id: "time", title: this.$t("timestamp")},
        {id: "category", title: this.$tc("titles.category", 1)},
        {id: "value", title: this.$t("value")}
      ];
      if (this.namedQuery) {
        lst.splice(0, 1);
      }
      return lst;
    },
    axisType: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form};
        form.type = value;
        if (form.type != "value") {
          resetScale(form, ["min", "max", "interval", "dataSerieId", "offset"]);
        }
        this.setForm(form);
        this.$store.dispatch("history/reset");
      },
      get() {
        return (this?.form || {})?.type || "time";
      }
    },
    dataSerieId: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form};
        form.dataSerieId = value;
        this.setForm(form);
      },
      get() {
        return (this?.form || {})?.dataSerieId ?? "";
      }
    },
    axisScale: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form, ...(value || {})};
        this.setForm(form);
      },
      get() {
        let entry = {
          min: this.axisProp("min"),
          max: this.axisProp("max"),
          interval: this.axisProp("interval"),
          offset: this.axisProp("offset")
        };
        return entry;
      }
    },
    axisTitle: {
      set(value) {
        if (!this.form) return;
        let form = {...this.form, ...(value || {})};
        this.setForm(form);
      },
      get() {
        return this.form;
      }
    },
    nameLocationCfgOptions() {
      return [
        {
          id: "start",
          icon: "glyphicon glyphicon-object-align-left",
          title: this.$t("left")
        },
        {
          id: "middle",
          icon: "glyphicon glyphicon-object-align-vertical",
          title: this.$t("center")
        },
        {
          id: "end",
          icon: "glyphicon glyphicon-object-align-right",
          title: this.$t("right")
        }
      ];
    },
    filteredSeries() {
      // since there is no timestamp to match, it does not allow standard curve as origin
      return (this.series || []).filter(
        ({chartOptions}) => chartOptions.datasetSource != "standard_curve"
      );
    }
  },
  methods: {
    injectExpression(axis) {
      axis[this.scale] = {...(axis[this.scale] || {})};
      let v1 = this.$utils.trim(this.expression);
      let v2 = this.$utils.trim(this.format);
      if (v1 !== "" || v2 !== "") {
        axis[this.scale].expression = `${v1}|${v2}`;
      } else {
        axis[this.scale].expression = "";
      }
      return axis;
    },
    setForm(payload) {
      if (payload && this.form && !isEqual(this.form, payload)) {
        this.form = payload;
        this.$emit("input", {custom: this.customAxis, data: payload});
      }
    },
    init() {
      const _initLocal = (name, value, dft) => {
        this[name] = (value ?? "") === "" ? dft : value;
      };
      this.custom = this.value?.custom ?? false;
      this.propName = this.value?.propName;
      this.form = structuredClone(this.value?.data || {});
      let exp = (
        ((this?.form || {})[this.scale]?.expression ?? "") ||
        ""
      ).split("|");
      _initLocal("expression", exp[0], "");
      _initLocal("format", exp[1], "");
      _initLocal("axisLabelRotation", this?.form?.axisLabel?.rotate, 0);
      _initLocal("axisLabelDistance", this?.form?.axisLabel?.margin, 8);
      if (
        this.allowTypeSelection &&
        !this.axisTypeOptions.find(({id}) => id == this.form.type)
      ) {
        this.form.type = "category";
      }
    },
    axisProp(name, value) {
      return axisProp(this, this.form, name, value);
    },
    sanitize() {},
    focus() {
      if (this.$refs.axisName) {
        this.$refs.axisName.focus();
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style scoped>
.toggle-panel {
  margin: 0px 5px 15px -15px;
  padding: 0 0 0 10px;
}

.toggle-panel::v-deep > .compressed-body-opened {
  padding: 10px 15px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
