// prettier-ignore
export default {
  pt: {
    titles: {
      selector: 'Seletor|Seletores',
      enabled: "Habilitado | Habilitados",
      process_area: "Área de processo|Áreas de processo",
      connector: "Conector|Conectores",
      device: "Dispositivo|Dispositivos",
      custom: "Customizado|Customizados",
      label: "Rótulo|Rótulos",
      expression: "Expressão|Expressões",
      placeholder: "Placeholder|Placeholders",
      hint: "Dica|Dicas",
      screen:"Tela|Telas",
      option:"Opção|Opções",
      show_null:"Mostrar nulo|Mostrar itens nulos",
      inverted: "Ordenação descendente"
    },
    hints: {
      enabled: "Caso habilitado, este filtro limitará os itens dos filtros subsequentes (se houver um).\nPor exemplo: area de processo limitará lista de conectores...",
      process_area: "Área de processo|Áreas de processo",
      connector: "Conector|Conectores",
      device: "Dispositivo|Dispositivos",
      custom: "Customizado|Customizados",
      label:"Texto a ser apresentado como titulo deste filtro",
      expression: "Expressão que extrai o texto usado na identificação de cada opção da lista apresentada",
      placeholder: "Texto apresentado quando o seletor estiver vazio",
      hint: "Texto de auxílio a ser apresentado na dica do seletor",
      screen:"Define a tela que será apresentada quando o usuário aplicar o filtro. A tela destino poderá ser diretamente atribuida (mantendo a tela corrente ou selecionando uma tela), ou indiretamente (tela proveniente do conector ou dispositivo",
      option: "Define quais opções de filtro serão apresentadas no painel",
      show_null:"Apresenta opções cuja expressão retorne um valor vazio",
      inverted: "Considerando o valor retornado pela expressão, a lista de opções será apresentada em ordem ascendente (padrão) ou descendente"
    },
  },
  en: {
    titles: {
      selector: 'Selector|Selectors',
      enabled: "Enabled | Enabled",
      process_area: "Process Area|Process Areas",
      connector: "Connector|Connectors",
      device: "Device|Devices",
      custom: "Customized|Customized",
      label: "Label|Labels",
      expression: "Expression|Expressions",
      placeholder: "Placeholder|Placeholders",
      hint: "Tip|Tips",
      screen:"Screen|Screens",
      option:"Options|Options",
      show_null:"Show Null|Show Nulls",
      inverted: "Invertido"
    },
    hints: {
      enabled: "If enabled, this filter will limit the items of subsequent filters (if any).\nFor example: process area will limit the list of connectors...",
      process_area: "Process Area|Process Areas",
      connector: "Connector|Connectors",
      device: "Device|Devices",
      custom: "Customized|Customized",
      label:"Text to be displayed as the title of this filter",
      expression: "Expression that extracts the text used to identify each option in the list presented",
      placeholder: "Text displayed when selector is empty",
      hint: "Help text to be displayed in the selector tooltip",
      screen:"It defines the source and screen that should be displayed after filter confirmation",
      option: "It defines which filter options will be displayed in the panel",
      show_null:"Displays options whose expression returns an empty value?",
      inverted: "Present in reverse order"
    },
  },
};
