<template>
  <div class="me">
    <Spin v-if="busy" />
    <!-- {{ customControls }} -->
    <TogglePanel
      title="titles.dynamic_data_exchange"
      :hint="`${$t(
        'hints.screen_data_mapping'
      )}<br/><i class='fa fa-plug'></i> ${$t('titles.remote_connector')}:  ${
        remoteConnector ? remoteConnector.name : ''
      }`"
      class="hinted-toggle"
    >
      <div class="main">
        <div class="form-group form-group-sm" style="margin-bottom: 0">
          <label for="">{{ $tc("panel", 1) }}</label>
          <select
            v-if="selectedPanel"
            class="form-control no-padding"
            v-model="panelName"
            :title="`${selectedPanel.originalTitle} - ${$t('position')} ${$tc(
              'layout',
              1
            )}: [${parseInt(selectedPanel.position.row) + 1},${
              parseInt(selectedPanel.position.col) + 1
            }]`"
          >
            <option
              v-for="item in filteredPanelList"
              :key="item.name"
              :value="item.name"
              :title="`${item.originalTitle} - ${$t('position')} ${$tc(
                'layout',
                1
              )}: [${parseInt(item.position.row) + 1},${
                parseInt(item.position.col) + 1
              }]`"
            >
              @{{ parseInt(item.position.row) + 1 }},{{
                parseInt(item.position.col) + 1
              }}
              -
              {{ item.customTitle || item.originalTitle }}
            </option>
          </select>
        </div>
        <template v-if="selectedPanel">
          <template v-if="selectedPanel.template == 'SynopticPanel'">
            <div v-if="customControls.length">
              <TogglePanel
                style="background-color: transparent; padding: 0 10px"
                title="customizable_controls"
              >
                <div>
                  <div v-for="control in customControls" :key="control.name">
                    <div
                      class="no-select"
                      @click.stop.prevent="
                        control.collapsed = !control.collapsed
                      "
                    >
                      <label class="clicable">- {{ control.name }}</label>
                      <div class="pull-right">
                        <span class="btn btn-xs">
                          <i
                            class="fa"
                            :class="
                              control.collapsed
                                ? 'fa-caret-square-o-up'
                                : 'fa-caret-square-o-down'
                            "
                          ></i>
                        </span>
                      </div>
                    </div>
                    <ControlDataSelector
                      :value="control.data_id"
                      :addon="$tc('data', 1)"
                      label=""
                      v-if="!control.collapsed"
                      @input="setSynopticControlDataId(control, $event)"
                      style="margin-bottom: 0"
                      :connectorFilter="(lst) => connectorListFilter(lst)"
                      :allowedTypes="['bool', 'float', 'int', 'string']"
                    />
                  </div>
                </div>
              </TogglePanel>
            </div>
            <div v-else class="text-center text-danger py-10">
              {{ $t("titles.there_are_no_named_data_controls") }}
            </div>
          </template>
          <template v-else>
            <div class="data-list-form">
              <!-- DashboardTablePanel -->
              <TogglePanel
                v-if="
                  selectedPanel.template == 'DashboardTablePanel' &&
                  tableCellRef.length
                "
                :title="$tc('data_list', 2)"
              >
                <div>
                  <div
                    v-for="item in tableCellRef"
                    :key="item.from"
                    class="data-item"
                  >
                    <div
                      class="data-item-header clicable"
                      :class="{ expanded: !item.collapsed }"
                      @click.stop.prevent="item.collapsed = !item.collapsed"
                    >
                      <div
                        class="text"
                        :title="itemTitle(item.to || item.from)"
                      >
                        <i class="fa fa-arrows-v"></i>
                        {{ dataName(item.to || item.from) }}
                      </div>
                      <div class="toggle">
                        <i
                          :class="
                            item.collapsed
                              ? 'fa fa-chevron-right'
                              : 'fa fa-chevron-down'
                          "
                        ></i>
                      </div>
                    </div>
                    <div class="data-item-body" v-if="!item.collapsed">
                      <div class="address" :title="item.address">
                        <span> {{ $tc("cell", 2) }}: </span>
                        {{ item.address }}
                      </div>
                      <template v-if="!item.collapsed">
                        <ControlDataSelector
                          v-if="
                            dataConnectorId(item.to || item.from) ||
                            screenConnectorId
                          "
                          label=""
                          :addon="$tc('data', 1)"
                          :connectorId="
                            dataConnectorId(item.to || item.from) ||
                            screenConnectorId
                          "
                          :value="item.to || item.from"
                          @input="setCellRef(item, $event)"
                          :connectorFilter="(lst) => connectorListFilter(lst)"
                        />
                        <ControlDataSelector
                          v-else
                          label=""
                          :addon="$tc('data', 1)"
                          :value="item.to || item.from"
                          @input="setCellRef(item, $event)"
                          :connectorFilter="(lst) => connectorListFilter(lst)"
                        />
                      </template>
                    </div>
                  </div>
                </div>
              </TogglePanel>
              <DataListForm
                v-else-if="hasDataListSupport"
                v-model="panelDataIdList"
                :labelItems="$tc('selected', 2, { gender: 'o' })"
                :labelSelection="$tc('data_list', 2)"
                :collapsed="panelDataListConfig.collapsed"
                :dataSelectionOnly="panelDataListConfig.dataSelectionOnly"
                :showAddAllDataButton="panelDataListConfig.showAddAllDataButton"
                :showAddNewDataButton="panelDataListConfig.showAddNewDataButton"
                :multiConnector="panelDataListConfig.multiConnector"
                :selectable="panelDataListConfig.selectable"
                :dataListParser="dataListParser"
                :connectorFilter="(lst) => connectorListFilter(lst)"
                @all="panelDataIdListAll = true"
              />
              <div v-else class="text-center text-danger py-10">
                {{ $t("data_mapping_not_available") }}
              </div>
            </div>
          </template>
        </template>
      </div>
    </TogglePanel>
  </div>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import Spin from "@/components/spin.vue";
import Controls from "@/assets/dashboard/controls.json";
import DashboardService from "@/services/dashboard";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import DataListForm from "@/components/control-sidebar/property-editors/data-list-form.vue";
import { DataListBasedPanels, panelPosition } from "@/services/dashboard";

export default {
  name: "SynopticCustomDataSetter",
  props: {
    screenId: {
      type: [Number, String],
      required: true
    },
    value: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  components: {
    TogglePanel,
    Spin,
    // Tooltip,
    ControlDataSelector,
    DataListForm
  },
  data: () => ({
    busy: false,
    template: null,
    customControls: [],
    dataListPanels: {},
    panelName: "",
    tablePanelsCellRef: {}
  }),
  computed: {
    dataList() {
      return (
        (this?.selectedPanel?.template == "DashboardTablePanel"
          ? this.$store.getters["dashboard/extendedDataList"]
          : this.$store.getters["dashboard/dataList"]) || []
      );
    },
    screen() {
      return this.$store.getters["dashboard/screen"](this.screenId);
    },
    panels() {
      return this.template.panels || [];
    },
    filteredPanelList() {
      if (!this.template) return [];
      let all = this.$store.getters["dashboard/allPanels"];
      return this.panels
        .filter(({ template }) => template in DataListBasedPanels)
        .map((panel) => {
          let original = all.find(
            ({ template }) => template.template == panel.template
          );
          var entry = {
            name: panel.name,
            template: panel.template,
            originalTitle: this.$t(`synoptic.panels.${original.title}`),
            customTitle: this.$t(`synoptic.panels.${original.title}`),
            position: panelPosition(this.template, panel.name),
            index: -1
          };
          entry.index =
            (entry.position.row + 1) * 100 + (entry.position.col + 1);
          return entry;
        })
        .sort((a, b) => {
          return a.index - b.index;
        });
    },
    selectedPanel() {
      let dft = (this.filteredPanelList || [])[0] || null;
      return (
        (this.filteredPanelList || []).find(
          ({ name }) => name == this.panelName
        ) || dft
      );
    },
    panelDataIdList: {
      set(values) {
        if (!this.panelName) return;
        let value = JSON.parse(JSON.stringify(this.value || { items: [] }));
        var item = value.items.find(({ id }) => id == this.panelName) || null;
        if (!item) {
          item = { id: this.panelName };
          value.items.push(item);
        }
        item.text = item.text || {};
        item.text.all = false;
        item.text.dataList = values;
        if ((values || []).length) {
          item.text.all = false;
        }
        this.$emit("input", value);
      },
      get() {
        var item = this.panelName
          ? (this?.value?.items || []).find(({ id }) => id == this.panelName)
          : null;
        item = item || { text: { dataList: [] } };
        return item?.text?.dataList || [];
      }
    },
    panelDataIdListAll: {
      set(value) {
        this.dataListPanels[this.selectedPanel.name].all = value;
        if (value) {
          this.$set(
            this.dataListPanels[this.selectedPanel.name],
            "dataList",
            []
          );
        }
      },
      get() {
        return this.dataListPanels[this.selectedPanel.name]?.all || false;
      }
    },
    panelDataListConfig() {
      return this.selectedPanel.template in DataListBasedPanels
        ? DataListBasedPanels[this.selectedPanel.template].config
        : {
            showAddNewDataButton: !this.panelDataIdListAll,
            showAddAllDataButton: !this.panelDataIdListAll,
            collapsed: true,
            dataSelectionOnly: false,
            multiConnector: true,
            selectable: false
          };
    },
    tableCellRef: {
      set(cell) {
        if (!cell) return;
        let value = JSON.parse(JSON.stringify(this.value || { items: [] }));
        var item = value.items.find(({ id }) => id == this.panelName) || null;
        if (!item) {
          item = { id: this.panelName };
          value.items.push(item);
        }
        item.text = item.text || {};
        item.text.mappedData = item.text.mappedData || [];

        let dataFrom = this.dataList.find(({ id, clp_id, reference_id }) => {
          return (
            (this.screenConnectorId == clp_id && reference_id == cell.from) ||
            id == cell.from
          );
        });

        if (!dataFrom) return;

        let dataTo = null;
        if (cell.to) {
          dataTo = this.dataList.find(({ id, clp_id, reference_id }) => {
            return (
              (this.screenConnectorId == clp_id && reference_id == cell.to) ||
              id == cell.to
            );
          });
          // if (dataTo && dataTo.id == dataFrom.id) return;
        }

        let pos = item.text.mappedData.findIndex(
          (dataMap) =>
            dataMap.from.data_id == dataFrom.id ||
            dataMap.from.data_id == dataFrom.reference_id
        );
        if (pos >= 0) {
          if (dataTo) {
            item.text.mappedData[pos].from.data_id = dataFrom.id;
            item.text.mappedData[pos].to.data_id = dataTo.id;
          } else {
            item.text.mappedData.splice(pos, 1);
          }
        } else {
          if (dataTo) {
            item.text.mappedData.push({
              from: { data_id: dataFrom.id },
              to: { data_id: dataTo.id }
            });
          }
        }
        this.$emit("input", value);
      },
      get() {
        let tableCellDataMap = this.tablePanelsCellRef[this.panelName] || null;
        if (!tableCellDataMap) return [];
        return Object.keys(tableCellDataMap)
          .map((dataId) => {
            return tableCellDataMap[dataId];
          })
          .sort((a, b) =>
            a.address > b.address ? 1 : b.address > a.address ? -1 : 0
          );
      }
    },
    screenConnectorId() {
      let refMap = this.$store.getters["dashboard/screenRefMap"](
        this.screenId
      ) || { conn1: this.$store.getters["dashboard/dashboardEquipmentId"] };
      return refMap?.conn1 || "";
    },
    hasDataListSupport() {
      let panel = this.selectedPanel
        ? this.panels.find(({ name }) => this.selectedPanel.name == name)
        : null;
      return (
        (panel &&
          DataListBasedPanels[this.selectedPanel.template].hasDataListSupport(
            panel
          )) ||
        false
      );
    },
    remoteConnector() {
      let connId = this?.screen?.reference_connectors?.length
        ? this?.screen?.reference_connectors[0].id
        : 0;
      return connId
        ? this.$store.getters["dashboard/connectorList"].find(
            ({ id }) => id == connId
          )
        : null;
    }
  },
  watch: {
    screenId: {
      handler(n) {
        if (n) {
          this.fetchRemoteData().then(() => {
            let template = this.$store.getters["dashboard/template"](n);
            if (!template) {
              this.fetchTemplate();
            } else {
              this.$set(this, "template", template);
            }
          });
        }
      },
      immediate: true
    },
    template: {
      handler(n) {
        if (n) {
          this.parsePanels();
          this.busy = false;
        }
      },
      immediate: true
    }
  },
  methods: {
    originalControlName(componentName) {
      let ctrl = (Controls || []).find(
        (i) => i?.template?.synopticComponent?.componentName == componentName
      );
      return ctrl && (ctrl?.template?.title || ctrl?.name || "");
    },
    fetchTemplate() {
      this.busy = true;
      this.srv.getTemplate(this?.screen?.path).then((template) => {
        this.$store.commit("dashboard/SET_TEMPLATE", {
          id: this.screenId,
          data: template
        });
        this.$set(this, "template", template);
      });
    },
    dataName(dataId) {
      let data = dataId ? this.dataList.find(({ id }) => id == dataId) : null;
      return (data && data.name) || "-";
    },
    itemTitle(dataId) {
      let data = this.dataList.find(({ id }) => dataId == id);
      if (!data) return "";
      let conn = data.clp_id
        ? this.$store.getters["dashboard/connectorList"].find(
            ({ id }) => id == data.clp_id
          )
        : null;
      let connInfo = conn ? `- ${this.$tc("connector", 1)}: ${conn.name}` : "";
      return `#id: ${data.id} ${connInfo}`;
    },
    dataConnectorId(dataId) {
      let data = dataId ? this.dataList.find(({ id }) => id == dataId) : null;
      return (data && data.clp_id) || "";
    },
    connectorListFilter(lst) {
      return (lst || []).sort((a, b) =>
        a.name > b.name ? 1 : a.name < b.name ? -1 : 0
      );
    },
    fetchRemoteData() {
      return new Promise((resolve) => {
        let connectorId = this.screenConnectorId;
        if (
          this?.screen?.reference_connectors &&
          this?.screen?.reference_connectors[0]
        ) {
          connectorId = (this?.screen?.reference_connectors || [])[0]?.id;
        }
        if (connectorId) {
          this.$store
            .dispatch("dashboard/fetchResourcesFrom", {
              resource: "data",
              connectorId: connectorId,
              forceUpdate: true
            })
            .then((r) => {
              resolve(r);
            });
        } else {
          resolve();
        }
      });
    },
    parsePanels() {
      let lst = [];
      if (!this.template) return;
      if (this.filteredPanelList.length) {
        this.panelName = this.filteredPanelList[0].name;
      }
      // synoptic
      let synoptic = this.panels.find(
        ({ template }) => template === "SynopticPanel"
      );
      if (synoptic) {
        let inserted = {};
        (synoptic?.options?.controls || []).forEach((control) => {
          if (
            !control.enabled ||
            !control.name ||
            !("data_id" in control) ||
            control.name in inserted
          )
            return;
          let name = this.originalControlName(
            control.synopticComponent.componentName
          );
          if (
            this.$utils.trim(name).toUpperCase() !==
            this.$utils.trim(control.name).toUpperCase()
          ) {
            inserted[control.name] = true;
            let ctrlData =
              (this?.value?.items || []).find(({ id }) => id == control.name) ||
              {};
            let value = ctrlData?.text?.data_id || ctrlData?.text || "";
            lst.push({
              name: control.name,
              data_id: value,
              collapsed: true
            });
          }
        });
        this.$set(
          this,
          "customControls",
          lst.sort((a, b) => {
            if (a.name > b.name) return -1;
            if (b.name > a.name) return 1;
            return 0;
          })
        );
        // make synoptic the selected if mapping controls are available
        let selected = lst.length
          ? (this.filteredPanelList || []).find(
              ({ template }) => template == "SynopticPanel"
            )
          : null;
        if (selected) {
          this.panelName = selected.name; // replace the default one
        }
      }
      //
      this.panels
        .filter(({ template }) => template === "DashboardTablePanel")
        .forEach((panel) => {
          let cpanel = JSON.parse(JSON.stringify(panel));
          DataListBasedPanels[panel.template].mapDataCells(cpanel);
          if (cpanel.options._dataCellRef) {
            // previous mapped data from this panel
            var item =
              (this?.value?.items || []).find(({ id }) => id == panel.name) ||
              null;
            item = item || { text: { dataList: [], mappedData: [] } };
            // build local map
            let entry = {};
            for (var dataId in cpanel.options._dataCellRef) {
              var cells = cpanel.options._dataCellRef[dataId] || [];
              for (var i = 0; i < cells.length; i++) {
                // if (cells[i].dataListPos == -1) {
                let data = this.dataList.find(({ id, reference_id }) => {
                  return (
                    reference_id == dataId || parseInt(id) == parseInt(dataId)
                  );
                });
                if (!data) continue;
                let dataMap = (item?.text?.mappedData || []).find(
                  (i) =>
                    i.from.data_id == data.id ||
                    i.from.data_id == data.reference_id
                );
                entry[data.id] = {
                  address: cells.map(({ address }) => address).join(","),
                  cells: cells,
                  from: data.id,
                  to: dataMap ? dataMap?.to?.data_id || "" : "",
                  collapsed: true
                };

                break;
                // }
              }
            }
            this.$set(this.tablePanelsCellRef, cpanel.name, entry);
          }
        });
    },
    setSynopticControlDataId(control, data_id) {
      control.data_id = data_id;
      let value = JSON.parse(JSON.stringify(this.value || { items: [] }));
      var pos = value.items.findIndex(({ id }) => id == control.name);
      // var item = value.items.find(({ id }) => id == control.name) || null;
      if (data_id) {
        var item = null;
        if (pos == -1) {
          item = { id: control.name };
          value.items.push(item);
        } else {
          item = value.items[pos];
        }
        item.text = { data_id: data_id };
      } else if (pos >= 0) {
        value.items.splice(pos, 1);
      }
      this.$emit("input", value);
    },
    dataListParser(lst) {
      var fn =
        this.selectedPanel.template in DataListBasedPanels
          ? DataListBasedPanels[this.selectedPanel.template]?.filter || null
          : null;
      return fn ? fn(lst) : lst;
    },
    setCellRef(cell, dataId) {
      if (cell.to != dataId) {
        cell.to = dataId;
        this.tableCellRef = cell;
        // cell.collapsed = true;
      }
    }
  },
  beforeCreate() {
    this.srv = new DashboardService();
  },
  beforeDestroy() {
    this.src = null;
  }
};
</script>

<style scoped>
.me {
  position: relative;
  padding: 0 10px;
  border-radius: 5px;
  background-color: white;
}

.skin-dark .me {
  background-color: transparent;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.main {
  background-color: transparent;
  /* margin: 0 -10px 10px 0;
  padding: 0 5px; */
  margin: 0 -2px;
}

.data-list-form {
  padding: 0 10px 10px 10px;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.data-item {
  position: relative;
  margin: 0 -10px;
  padding: 0;
  font-weight: 600;
  margin-bottom: 2px;
}

.data-item > .data-item-header {
  padding: 0 4px 6px 2px;
}

.data-item > .data-item-header > .text {
  width: calc(100% - 22px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-item > .data-item-header > .text > i {
  opacity: 0.6;
  margin-right: 6px;
  cursor: not-allowed;
}

.data-item > .data-item-header.expanded {
  color: #337ab7;
  background: whitesmoke;
}

.data-item > .data-item-header > .toggle {
  position: absolute;
  right: 6px;
  top: 2px;
}

.data-item > .data-item-body {
  padding: 0 5px 5px 5px;
  background: whitesmoke;
  border-radius: 5px;
}

.data-item > .data-item-body > .address {
  max-width: calc(100% - 22px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.data-item > .data-item-body > .address > span {
  margin-right: 5px;
  color: #777;
  font-weight: normal;
}

.addon-label {
  font-weight: normal;
  padding: 0 6px;
  min-width: 50px;
}

.py-10 {
  padding: 10px 0;
}
.hinted-toggle {
  z-index: 101;
}
</style>
